<template>
  <div id="map" class="map"></div>
</template>
<script>
import L from "leaflet";
import "leaflet/dist/leaflet.css";
export default {
  data() {
    return {};
  },
  mounted() {
    this.createMap();
  },
  methods: {
    createMap() {
      const map = L.map("map").setView(
        { lon: 127.1013397, lat: 37.4043696 },
        13
      );

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(
        map
      );
    },
  },
};
</script>
<style scoped lang="scss">
.map {
  width: 100%;
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  z-index: 4;
}
</style>
