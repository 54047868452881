<template>
  <HeaderLayout />
  <GlobalNavBar />
  <div id="container">
    <div id="contents" style="padding-bottom: 40px">
      <BreadcrumbLayout
        pageId="monTASK_multiAgentMonitoring"
        :name="'[TASK_AD] ' + taskData.taskName"
      />
      <div class="item_info">
        <div class="item_title">
          <strong class="task_project"
            >[PROJECT] {{ prjData.project_name }}</strong
          >
          <div class="item_info_btn_box">
            <button type="button" class="btn_fold" @click="hideProjectInfo">
              {{ foldStatus }}
            </button>
            <button
              type="button"
              class="btn_evt_group"
              @click="hideUtils"
            ></button>
            <ul
              class="evt_btn_box radiusbox"
              :style="{ display: InferenceUtil ? 'block' : 'none' }"
            >
              <li class="download_item">
                <button type="button" @click="makePDF">데이터 다운로드</button>
              </li>
              <li class="share_item">
                <button type="button" @click="clipCoppy">공유하기</button>
              </li>
              <li class="retouch_item" v-if="manageRole">
                <button type="button" @click="taskModify">TASK 수정</button>
              </li>
            </ul>
          </div>
        </div>
        <div class="item_table radiusbox" v-if="visibleProjectInfo">
          <table>
            <thead>
              <tr>
                <th>Project ID</th>
                <th>AWS ID</th>
                <th>PM</th>
                <th>Project Type</th>
                <th>Unit</th>
                <th>Last Activity</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ prjData.project_name }}</td>
                <td>{{ prjData.aws_id }}</td>
                <td>{{ prjData.pm }}</td>
                <td>{{ prjData.project_type }}</td>
                <td>{{ prjData.team_name }}</td>
                <td>{{ prjData.last_activity }}</td>
              </tr>
            </tbody>
          </table>
          <div class="item_desc">
            <div class="desc_title">Description</div>
            <div class="desc_txt">{{ prjData.project_description }}</div>
          </div>
        </div>
      </div>
      <div id="monCont" class="moa_dete">
        <div class="time_record">
          <div>
            <span>Time Record</span>
            <span>07 : 52 : 56</span>
          </div>
          <div>
            <span>2024.08.21.</span>
            <span>/</span>
            <span>08:24:32 Started</span>
          </div>
          <div>
            <button>개별관제 ↗</button>
          </div>
        </div>
        <div class="float_box radiusbox">
          <div class="status_wrap">
            <div class="status_top">
              <div>Status</div>
              <div>
                <span>ldle : </span>
                <span>0</span>
              </div>
              <div>
                <span>On Mission : </span>
                <span>2</span>
              </div>
              <div>
                <span>Disconnected : </span>
                <span>1</span>
              </div>
            </div>
            <table class="mando_table">
              <thead>
                <tr>
                  <th>
                    <div class="col_name">Dvc_id</div>
                  </th>
                  <th>
                    <div class="col_name">Operation_Status</div>
                  </th>
                  <th>
                    <div class="col_name">Mission Status</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div>wm_robot1</div>
                  </td>
                  <td>
                    <div>Battery : 100%</div>
                    <div>Connection : 100%</div>
                  </td>
                  <td>
                    <div>mission_id : 7864</div>
                    <div>Progress : 30%</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="radiusbox" style="width: 100%; height: 500px">
          <MapController />
        </div>
        <div class="radiusbox" style="width: 100%; height: 500px">
          <div class="title_box">
            <strong>Cpu Usage / Core</strong>
          </div>
          <ChartController />
        </div>
        <div class="radiusbox" style="width: 100%; height: 500px">
          <div class="title_box">
            <strong>Data Recording</strong>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="lodingWrap" style="display: none" ref="lodingWrap">
    <div class="loading-container">
      <div class="loding-animation-holder">
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="middle-circle"></div>
      </div>
    </div>
  </div>
  <FooterLayout />
</template>

<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";
import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";
import common from "@/assets/js/common";
import apiCallVectorLogger from "@/assets/js/apiCallVectorLogger";
import ChartController from "@/components/ChartController.vue";
import MapController from "@/components/MapController.vue";

export default {
  name: "MultiAgentMonitoringView",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    BreadcrumbLayout,
    ChartController,
    MapController,
  },
  data() {
    return {
      // project , task 정보
      taskData: {
        projectId: null,
        projectName: null,
        awsId: null,
        taskName: null,
        creatorName: null,
        type: null,
        teamName: null,
        description: null,
      },

      // OEM 선택, Vehicle 선택, Edge ID 선택
      oemSelect: "0",
      vehicleSelect: "0",
      edgeSelect: "0",
      edgeSelectName: "",

      carSelect: "0",

      oemId: 0,
      vehicleId: 0,
      edgeId: 0,

      carNumber: 0,

      oemOption: [],
      vehicleOption: [],
      edgeOption: [],
      carOption: [],

      selectDate: "1",

      //DTC Map

      foldStatus: "접기",
      InferenceUtil: false,

      visibleProjectInfo: true,

      prjData: {
        project_name: "",
        aws_id: "",
        pm: "",
        project_type: "",
        team_name: "",
        last_activity: "",
        project_description: "",
      },

      projectId: "",

      // role
      viewRole: false,
      manageRole: false,

      carDataList: [],
      carDataDetail: null,
      carDataDetailList: [],
    };
  },

  computed: {},
  async mounted() {
    // Role
    await this.setRole();

    // prj info
    const prjId = this.$route.query.prjId;
    const prjData = await this.getPrjData(prjId);
    if (typeof prjData !== "undefined" && prjData !== null && prjData !== "") {
      this.prjData = prjData.project;
    }

    try {
      // monitoring-task 정보
      await common
        .apiGet(
          "/monitoring-task/" +
            this.$route.query.taskId +
            "?viewRole=" +
            this.viewRole
        )
        .then((r) => {
          console.log("0. monitoring-task 정보");
          console.log(r.data.data);
          this.taskData.projectId = r.data.data.projectId;
          this.taskData.projectName = r.data.data.projectName;
          this.taskData.awsId = r.data.data.awsId;
          this.taskData.taskName = r.data.data.taskName;
          this.taskData.creatorName = r.data.data.creatorName;
          this.taskData.type = r.data.data.type;
          this.taskData.teamName = r.data.data.teamName;
          this.taskData.lastVisit = r.data.data.lastVisit;
          this.taskData.description = r.data.data.description;
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    try {
      // monitoring-task 에 속한 edge-device 정보
      await common
        .apiGet(
          "/edge-device/monitoring-task/edge-device-group/" +
            this.$route.query.taskId
        )
        .then((r) => {
          console.log("0. monitoring-task 에 속한 edge-device 정보");
          console.log(r.data.data);
          const o = [];
          r.data.data.forEach((e) => {
            if (!o.some((item) => item.id === e.id)) {
              o.push({
                id: e.id,
                value: e.edge_device_id,
                oem_id: e.oem_id,
                vehicle_id: e.vehicle_id,
              });
            }
          });
          this.edgeOption = o; // edge 선택 option
          this.edgeSelect = this.edgeOption[0].id; // 첫번째 선택
          this.edgeSelectName = this.edgeOption[0].value;
          this.oemSelect = this.edgeOption[0].oem_id; // 첫번째 oem 선택
          this.vehicleSelect = this.edgeOption[0].vehicle_id; // 첫번째 vehicle 선택

          const edgeIds = [];
          this.edgeOption.forEach((e) => {
            edgeIds.push(e.id);
          });
          // 테스트용 ID //
          edgeIds.push(1);
          edgeIds.push(2);
          // --테스트용 ID-- //
          //console.log(edgeIds);
          this.callCarApi(edgeIds);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    // mando api call
    await this.apiCallMando();

    //projectId
    this.projectId = this.$route.query.prjId;
    // await this.allowAccess();
  },
  watch: {
    oemSelect() {
      if (this.oemSelect == "0") this.oemId = "";
      else this.oemId = this.oemSelect;
      this.getVehicleSelect(this.oemSelect);
    },
    vehicleSelect() {
      if (this.vehicleSelect == "0") this.vehicleId = "";
      else this.vehicleId = this.vehicleSelect;
    },
    edgeSelect() {
      if (this.edgeSelect == "0") this.edgeId = "";
      else this.edgeId = this.edgeSelect;
    },
    carSelect() {
      if (this.carSelect == "0") this.carNumber = "";
      else this.carNumber = this.carSelect;
    },
    selectDuration() {
      this.setDate(this.selectDuration);
    },
  },
  methods: {
    carDetail(e, carNumber) {
      document.querySelectorAll(".data_list").forEach((e) => {
        e.classList.remove("active");
      });
      e.target.classList.add("active");

      this.carDataDetailList = [];
      this.carDataDetail = {};
      this.carDataList.forEach((e) => {
        if (e.car_number == carNumber) {
          this.carDataDetail.car_number = e.car_number;
          this.carDataDetail.car_type = e.car_type;
          this.carDataDetail.driver = e.driver;
          this.carDataDetail.logging_date = e.logging_date;

          this.carDataDetailList = e.items;
        }
      });
      console.log(".//////////// :: ", this.carDataDetailList);
    },
    async callCarApi(edgeIds) {
      console.log("신규 API 결과 ::===============");
      try {
        const param = {
          edgeIds: edgeIds,
        };
        const response = await apiCallVectorLogger.post(
          "/vl/vl_car_list/",
          param
        );
        console.log("신규 API 결과 :: ", response);
        if (response.status === 200 || response.status === "200") {
          this.carOption = response.data.car_numbers;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getCarLogger() {
      try {
        const param = {
          car_number: "0776",
          keyword: "",
          start: "2024-07-01",
          end: "2024-07-05",
          offset: 0,
          limit: 5,
        };
        const response = await apiCallVectorLogger.post("/vl/vl_items/", param);
        if (response.status === 200 || response.status === "200") {
          this.carDataList = response.data.can_items;
          console.log("차량 정보 :: ", this.carDataList);
        }
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },
    async setRole() {
      const prjId = this.$route.query.prjId;
      this.manageRole =
        (await common.getUserRole("type2")) ||
        (await common.getPrjRole("type2", prjId));
      this.viewRole =
        (await common.getUserRole("type2")) ||
        (await common.getPrjRole("type1", prjId));

      if (!this.viewRole) {
        common.goHome();
      }
    },

    /**************
     * select box
     **************/
    async getOemSelect() {
      try {
        const response = await common.apiGet(
          "/edge-device-group/domain/oem/vehicle/1"
        );
        const o = [];
        response.data.data.forEach((e) => {
          o.push({ id: e.id, value: e.name });
        });
        this.oemOption = o;
      } catch (error) {
        console.log("Fail: /edge-device-group/domain/oem/vehicle/1");
      }
    },
    async getVehicleSelect(id) {
      try {
        const o = [];
        if (id != 0) {
          const response = await common.apiGet(
            "/edge-device-group/domain/oem/vehicle/" + id
          );
          response.data.data.forEach((e) => {
            o.push({ id: e.id, value: e.name });
          });
        }
        this.vehicleOption = o;
      } catch (error) {
        console.log("Fail: /edge-device-group/domain/oem/vehicle/" + id);
      }
    },

    async apiCallMando() {
      // init
      //this.initAnomaly();

      const vm = this;

      // const $drivReclistBox = vm.$refs.drivReclistBox;

      // $drivReclistBox.style.display = 'block';

      // 로딩 시작
      vm.$refs.lodingWrap.style.display = "block";

      // OEM 선택
      await this.getOemSelect();

      if (this.carSelect != 0) {
        await this.getCarLogger();
      }

      // 로딩 시작 끝
      vm.$refs.lodingWrap.style.display = "none";
    },

    nowTime() {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    // 프로젝트정보 , 부가기능
    async hideProjectInfo() {
      // 프로젝트 정보 - 접기 , 펼치기
      if (this.visibleProjectInfo == true) {
        this.visibleProjectInfo = false;
        this.foldStatus = "펼치기";
      } else {
        this.visibleProjectInfo = true;
        this.foldStatus = "접기";
      }
    },
    hideUtils() {
      // 부가기능 - 보이기 숨기기
      if (this.InferenceUtil == true) {
        this.InferenceUtil = false;
      } else if (this.InferenceUtil == false) {
        this.InferenceUtil = true;
      }
    },
    async clipCoppy() {
      // 공유하기
      let dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      try {
        const url = window.document.location.href;
        dummy.value = url;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        alert("url copy success");
      } catch (error) {
        console.error(error);
      }
    },
    async taskModify() {
      // 수정
      if (this.manageRole) {
        const taskId = this.$route.query.taskId;
        const prjId = this.$route.query.prjId;
        const taskTypeId = this.$route.query.taskTypeId;
        const query = {
          prjId: prjId,
          taskId: taskId,
          taskTypeId: taskTypeId,
        };
        // console.log(taskId);
        try {
          this.$router.push({
            name: "AddTask",
            query,
          });
        } catch (error) {
          console.error("Error:", error);
        }
      }
    },

    async getPrjData(prjId) {
      try {
        const response = await common.apiGet("/project/" + prjId);
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    //-------API------
    async getMemberRole() {
      let response;
      try {
        let url = `/member/check-role`;
        response = await common.apiGet(url);
        return response.data.data;
      } catch (error) {
        console.error("error", error);
        return null;
      }
    },
    async getProjectRole() {
      let response;
      try {
        let checkProjectRoleUrl = `/member/check-project-role/${this.projectId}`;
        response = await common.apiGet(checkProjectRoleUrl);
        return response.data.data;
      } catch (error) {
        console.error("error", error);
        return null;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.moa_dete {
  overflow: hidden;
}
.mando_table {
  position: static !important;
}
.title_box {
  position: relative;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 15px;
  strong {
    font-weight: $bold;
  }
}
</style>
