<template :disabled="isDisabled">
  <div class="component" >
    <!-- <div class="comp_line">
      <div class="data_key">Division</div>
      <div class="data_val">
        <select name="division" ref="name" :value="division.name" @change="setDivision($event)">
               <option value="" disabled selected>선택해주세요.</option>
               <option v-for="(item) in divisionList" :key="item.id" :value="item.name">
                   {{item.name}}
               </option>
        </select>
      </div>
    </div> -->
    <!-- <div class="comp_line">
      <div class="data_key">Date</div>
      <div class="data_val">
        <div class="datePeriod">
          <Datepicker v-model="picked" /> ~ <Datepicker v-model="picked2" />
          <button class="dateon">검색</button>
        </div>
      </div>
    </div> -->
    <!-- <div class="divider"></div> -->
  </div>
</template>

<script>
import common from '@/assets/js/common';
// import Datepicker from "vue3-datepicker";

export default {
  components: {
    // Datepicker,
  },
  name: "FilterComponent",
 
  props: {
    otherProp: {
      type: Object,
      required: true,  // 전달받는 값이 반드시 있어야 한다면 required를 true로 설정
    },
    interface: {
      type: Object,
      required: true,
    },
    editYn:{
      type:Object,
      require:true
    }
  },

  data() {
    return {    
      picked: new Date(),
      picked2: new Date(),
      divisionList:[],
      division:{}
      };
  },

  mounted() {
    this.setRole();
    this.getDivisionList();
  },
  methods: {
    async setRole() {
        this.manageRole = true;
        this.viewRole = true;
    },
    async getDivisionList(){
      const response = await common.apiGet("/division");
      this.divisionList = response.data.data;
      console.log(this.divisionList)
      //여기에서 가져와야하는데..
      
    },
    setDivision(event){
      this.division['name'] = event.target.value;
    }
  },
  computed:{
    isDisabled(){
      return this.editYn === 'N';
    }
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.comp_line {
  display: flex;
  width: 100%;
  height: auto;
  min-height: 40px;
  flex-direction: row;
}
.comp_line .data_key {
  flex-grow: 1;
  height: 40px;
  line-height: 40px;
  padding-right: 20px;
}
.comp_line .data_val {
  flex-grow: 4;
}
.comp_line .data_val select {
  width: 100%;
  height: 40px;
  background-position: right 10px center;
}
</style>

<style>
.datePeriod {
  width: 100%;
  height: 40px;
  border: 1px solid #d4d4d4;
  border-radius: 10px;
  position: relative;
  padding-right: 80px;
  padding-left: 20px;
  font-size: 12px;
  font-weight: normal;
  color: black;
  text-align: Center;
}
.datePeriod button.dateon {
  width: 60px;
  height: 38px;
  border: none;
  border-left: 1px solid #d4d4d4;
  background: #f4f4f4;
  text-align: center;
  color: black;
  font-size: 12px;
  font-weight: normal;
  border-radius: 0px 10px 10px 0px;
  position: absolute;
  top: 0px;
  right: 0px;
}
.datePeriod input[type="text"] {
  width: calc(50% - 14px);
  height: 30px;
  border: 0px solid #d4d4d4;
  border-bottom: 1px solid #d4d4d4;
  text-align: center;
  font-size: 13px;
  font-weight: normal;
  color: black;
}
.divider {
  width: 100%;
  height: 1px;
  background: #d4d4d4;
  margin-top: 20px;
  margin-bottom: 20px;
}
.component {
  width: 100%;
  position: relative;
  height: 100%;
  overflow: auto;
}
.v3dp__datepicker {
  width: calc(50% - 14px);
  height: 30px;
  border: 0px solid #d4d4d4;
  border-bottom: 1px solid #d4d4d4;
  text-align: center;
  font-size: 13px;
  font-weight: normal;
  color: black;
  display: inline-block;
  vertical-align: middle;
}
.v3dp__input_wrapper {
  width: 100% !important;
}
.v3dp__input_wrapper input[type="text"] {
  width: 100% !important;
  border: none !important;
  border-bottom: 1px solid #d4d4d4 !important;
}
</style>
