<template>
  <HeaderLayout />
  <GlobalNavBar />
  <div id="container">
    <div id="contents">
      <BreadcrumbLayout :pageId="pageId" :checkRole="manageRole" />
      <div id="management">
        <div class="row_box">
          <div class="data_key">API 명</div>
          <div class="data_val" :class="{ failure: isEmptyApiNm }">
            <input
              type="text"
              name="apiNm"
              ref="apiNm"
              maxlength="50"
              v-model="apiNm"
              placeholder="입력해주세요."
            />
            <div class="text_message" v-if="isEmptyApiNm">
              API명을 입력해 주세요.
            </div>
            <div class="text_length">
              <span>{{ apiNmLen }}</span
              >/50
            </div>
          </div>
        </div>
        <div class="row_box">
          <div class="data_key">API 주소</div>
          <div class="data_val" :class="{ failure: isEmptyApiUrl }">
            <input
              type="text"
              name="apiUrl"
              ref="apiUrl"
              maxlength="50"
              v-model="apiUrl"
              placeholder="입력해주세요."
            />
            <div class="text_message" v-if="isEmptyApiUrl">
              API 주소를 입력해 주세요.
            </div>
            <div class="text_length">
              <span>{{ apiUrlLen }}</span
              >/50
            </div>
          </div>
        </div>
        <div class="row_box">
          <div>
            <div class="data_key">Project</div>
            <div class="data_val" :class="{ failure: isEmptyPrjId }">
              <select
                name="prjId"
                ref="prjId"
                :value="prjId"
                @change="setPrjId($event)"
              >
                <option value="" disabled selected>
                  프로젝트를 선택해 주세요.
                </option>
                <option
                  v-for="(item, index) in prjList"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.projectName }}
                </option>
              </select>
              <div class="text_message" v-if="isEmptyPrjId">
                프로젝트를 선택해 주세요.
              </div>
            </div>
          </div>
        </div>
        <div
          class="row_box"
          v-if="apiLogger && apiLogger != null && apiLogger != ''"
        >
          <div class="data_key">API Logger 주소</div>
          <div class="data_val">
            <input type="text" :value="apiLogger" disabled />
          </div>
        </div>
        <div class="row_box">
          <div>
            <div class="data_key">API 유형</div>
            <div class="data_val">
              <select
                name="apiCategory"
                ref="apiCategory"
                :value="apiCategory"
                @change="chgApiCategory($event)"
              >
                <option value="001">REST</option>
                <option value="002">HTTP</option>
                <option value="003">Websocket</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row_box">
          <div class="data_key">API 함수</div>

          <div class="data_val data_val_tbl active" ref="apiCategoryREST">
            <div class="data_val_tbl_left">REST</div>
            <div class="data_val_tbl_right">
              <div class="data_val_tbl_row data_val_tbl_title">
                <div>Method Name</div>
                <div>Method</div>
                <div>Parameter</div>
              </div>
              <div
                class="data_val_tbl_row data_val_tbl_data"
                v-for="(item, index) in restList"
                :key="index"
              >
                <div class="data_val_tbl_row_data">
                  <div>
                    <div class="data_val">
                      <input
                        type="text"
                        maxlength="50"
                        placeholder="입력해주세요."
                        class="data_name"
                        style="padding-right: 50px"
                        :value="item.funcName"
                      />
                    </div>
                    <div class="text_message" style="display: none">
                      Method Name을 입력해 주세요.
                    </div>
                  </div>
                  <div>
                    <div class="data_val">
                      <input
                        type="text"
                        maxlength="10"
                        placeholder="입력해주세요."
                        class="data_path"
                        style="padding-right: 50px"
                        :value="item.method"
                      />
                    </div>
                    <div class="text_message" style="display: none">
                      Method를 입력해 주세요.
                    </div>
                  </div>
                  <div>
                    <!-- <div class="data_val_btn_wrap"> -->
                    <template v-if="item.parameter">
                      <div
                        class="data_val_btn_wrap"
                        v-for="(item2, index) in item.parameter"
                        :key="index"
                      >
                        <div class="data_val">
                          <input
                            type="text"
                            maxlength="50"
                            placeholder="입력해주세요."
                            class="data_param"
                            style="padding-right: 50px"
                            :value="item2"
                          />
                        </div>
                        <div class="data_val_btn">
                          <button
                            class="btn_add_item add_param"
                            @click="addParam($event)"
                            :style="
                              item.parameter.length != index + 1
                                ? 'display:none'
                                : ''
                            "
                          >
                            Parameter 추가
                          </button>
                          <button
                            type="button"
                            class="save_box remove_param"
                            @click="removeParam($event)"
                            :style="restList.length > 1 ? 'display: none' : ''"
                          >
                            삭제
                          </button>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="data_val_btn_wrap">
                        <div class="data_val">
                          <input
                            type="text"
                            maxlength="50"
                            placeholder="입력해주세요."
                            class="data_param"
                            style="padding-right: 50px"
                            :value="item2"
                          />
                        </div>
                        <div class="data_val_btn">
                          <button
                            class="btn_add_item add_param"
                            @click="addParam($event)"
                          >
                            Parameter 추가
                          </button>
                          <button
                            type="button"
                            class="save_box remove_param"
                            @click="removeParam($event)"
                            style="display: none"
                          >
                            삭제
                          </button>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="data_val_tbl_row_btn">
                  <button
                    type="button"
                    class="btn_add_item add_line"
                    @click="addLine($event)"
                  >
                    Method 추가
                  </button>
                  <button
                    type="button"
                    class="save_box remove_line"
                    style="display: none"
                    @click="removeLine($event)"
                  >
                    삭제
                  </button>
                  <div class="add_fee">
                    <div class="data_key">과금 유무</div>
                    <div class="data_val">
                      <select class="data_fee">
                        <option value="N">N</option>
                        <option value="Y">Y</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="data_val data_val_tbl active"
            ref="apiCategoryHTTP"
            style="display: none"
          >
            <div class="data_val_tbl_left">HTTP</div>
            <div class="data_val_tbl_right">
              <div class="data_val_tbl_row data_val_tbl_title">
                <div>Method Name</div>
                <div>Method</div>
                <div>Parameter</div>
              </div>
              <div
                class="data_val_tbl_row data_val_tbl_data"
                v-for="(item, index) in httpList"
                :key="index"
              >
                <div class="data_val_tbl_row_data">
                  <div>
                    <div class="data_val">
                      <input
                        type="text"
                        maxlength="50"
                        placeholder="입력해주세요."
                        class="data_name"
                        style="padding-right: 50px"
                        :value="item.funcName"
                      />
                    </div>
                    <div class="text_message" style="display: none">
                      Method Name을 입력해 주세요.
                    </div>
                  </div>
                  <div>
                    <div class="data_val">
                      <input
                        type="text"
                        maxlength="10"
                        placeholder="입력해주세요."
                        class="data_path"
                        style="padding-right: 50px"
                        :value="item.method"
                      />
                    </div>
                    <div class="text_message" style="display: none">
                      Method를 입력해 주세요.
                    </div>
                  </div>
                  <div>
                    <template v-if="item.parameter">
                      <div
                        class="data_val_btn_wrap"
                        v-for="(item2, index) in item.parameter"
                        :key="index"
                      >
                        <div class="data_val">
                          <input
                            type="text"
                            maxlength="50"
                            placeholder="입력해주세요."
                            class="data_param"
                            style="padding-right: 50px"
                            :value="item2"
                          />
                        </div>
                        <div class="data_val_btn">
                          <button
                            class="btn_add_item add_param"
                            @click="addParam($event)"
                            :style="
                              item.parameter.length != index + 1
                                ? 'display:none'
                                : ''
                            "
                          >
                            Parameter 추가
                          </button>
                          <button
                            type="button"
                            class="save_box remove_param"
                            @click="removeParam($event)"
                            :style="httpList.length > 1 ? 'display: none' : ''"
                          >
                            삭제
                          </button>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="data_val_btn_wrap">
                        <div class="data_val">
                          <input
                            type="text"
                            maxlength="50"
                            placeholder="입력해주세요."
                            class="data_param"
                            style="padding-right: 50px"
                            :value="item2"
                          />
                        </div>
                        <div class="data_val_btn">
                          <button
                            class="btn_add_item add_param"
                            @click="addParam($event)"
                          >
                            Parameter 추가
                          </button>
                          <button
                            type="button"
                            class="save_box remove_param"
                            @click="removeParam($event)"
                            style="display: none"
                          >
                            삭제
                          </button>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="data_val_tbl_row_btn">
                  <button
                    type="button"
                    class="btn_add_item add_line"
                    @click="addLine($event)"
                  >
                    Method 추가
                  </button>
                  <button
                    type="button"
                    class="save_box remove_line"
                    style="display: none"
                    @click="removeLine($event)"
                  >
                    삭제
                  </button>
                  <div class="add_fee">
                    <div class="data_key">과금 유무</div>
                    <div class="data_val">
                      <select class="data_fee">
                        <option value="N">N</option>
                        <option value="Y">Y</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="data_val data_val_tbl data_category_websocket"
            ref="apiCategoryWebsocket"
            style="display: none"
          >
            <div class="data_val_tbl_left">Websocket</div>
            <div class="data_val_tbl_right">
              <div class="data_val_tbl_row data_val_tbl_title">
                <div>Method Name</div>
                <div>Method Definition</div>
                <div>Path</div>
              </div>
              <div
                class="data_val_tbl_row data_val_tbl_data"
                v-for="(item, index) in webSocketList"
                :key="index"
              >
                <div class="data_val_tbl_row_data" style="min-height: 100px">
                  <div>
                    <div class="data_val">
                      <input
                        type="text"
                        maxlength="50"
                        placeholder="입력해주세요."
                        class="data_name"
                        style="padding-right: 50px"
                        :value="item.funcName"
                      />
                    </div>
                    <div class="text_message" style="display: none">
                      Method Name을 입력해 주세요.
                    </div>
                  </div>
                  <div>
                    <div class="data_val">
                      <!-- <input
                        type="text"
                        maxlength="50"
                        placeholder="입력해주세요."
                      />
                      <div class="text_message" style="display: none">
                        중복된 Team Name입니다.
                      </div>
                      <div class="text_length"><span>0</span>/50</div> -->
                      <select class="data_def" @change="chgDataDef($event)">
                        <option value="1">$connect</option>
                        <option value="2">$disconnect</option>
                        <option value="3">$default</option>
                        <option value="3">$custom</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <div class="data_val_btn_wrap">
                      <div class="data_val">
                        <input
                          type="text"
                          maxlength="50"
                          placeholder="입력해주세요."
                          class="data_param"
                          style="padding-right: 50px"
                          :value="item.msg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="data_val_tbl_row_btn">
                  <button
                    type="button"
                    class="btn_add_item add_line"
                    @click="addLine($event)"
                  >
                    Method 추가
                  </button>
                  <button
                    type="button"
                    class="save_box remove_line"
                    style="display: none"
                    @click="removeLine($event)"
                  >
                    삭제
                  </button>
                  <div class="add_fee">
                    <div class="data_key">과금 유무</div>
                    <div class="data_val">
                      <select class="data_fee">
                        <option value="N">N</option>
                        <option value="Y">Y</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="save_box">
          <button type="button" @click="showModalAlert">취소</button
          ><button type="button" class="mcbtn" @click="clickRegist">
            저장
          </button>
        </div>
      </div>
    </div>
  </div>
  <div id="modalWrap" ref="modalWrap" style="display: none">
    <!-- 취소 -->
    <div
      class="radiusbox modal_contents message_alert message_modal"
      ref="modalAlert"
    >
      <div class="message_box">
        변경 사항이 저장되지 않았습니다. <br />정말 취소하시겠습니까?
      </div>
      <button type="button" class="btn_check mcbtn" @click="goBackPage()">
        확인
      </button>
      <button type="button" class="btn_close" @click="closeModal()"></button>
    </div>
  </div>
  <FooterLayout />
</template>
<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";
import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";
import "@/assets/js/filter";
import common from "@/assets/js/common";

export default {
  name: "apiManageList",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    BreadcrumbLayout,
  },
  data() {
    return {
      apiId: "",
      apiData: {},
      apiNm: "",
      apiUrl: "",
      apiLogger: "",
      apiCategory: "001",
      apiCategoryNm: "",
      pageId: "sysOps_addApiManage",
      prjInfoToggle: false,
      prjList: [],
      prjId: "",
      isEmptyApiNm: false,
      isEmptyApiUrl: false,
      isEmptyPrjId: false,
      teamNm: "",
      httpList: 1,
      restList: 1,
      webSocketList: 1,
      formData: {},
    };
  },
  created() {},

  async mounted() {
    // 권한 부여
    await this.setRole();
    // 프로젝트 리스트 로딩
    await this.setPrjSelect();
    /*
    console.log(this.apiData);

    if (typeof prjId !== "undefined" && prjId !== null && prjId !== "") {
      this.prjId = prjId;
      this.hasPrjId = true;
      }
      */
    this.apiId = this.$route.params.apiId;
    if (
      typeof this.apiId != "undefined" &&
      this.apiId != null &&
      this.apiId != ""
    ) {
      this.pageId = "sysOps_modApiManage";
      await this.setApiDetail(this.apiId);
    } else {
      this.pageId = "sysOps_addApiManage";
    }
    this.prjId = this.apiData.projectId;
  },

  computed: {
    apiNmLen() {
      return this.apiNm.length;
    },
    apiUrlLen() {
      return this.apiUrl.length;
    },
    requireCheck() {
      let apiNm =
        this.apiNm.length > 0 && this.apiNm.length <= 50 && this.apiNm != "";
      let apiUrl =
        this.apiUrl.length > 0 && this.apiUrl.length <= 50 && this.apiUrl != "";

      if (apiNm && apiUrl) {
        return true;
      }
      return false;
    },
  },

  methods: {
    // Modal
    showModalAlert() {
      const $modal = this.$refs.modalWrap;
      const $modalAlert = this.$refs.modalAlert;
      $modal.style.display = "block";
      $modalAlert.style.display = "block";
    },
    closeModal() {
      const $modal = this.$refs.modalWrap;
      const $modalAlert = this.$refs.modalAlert;
      // const $modalMember = this.$refs.modalMember;
      $modal.style.display = "none";
      $modalAlert.style.display = "none";
      // $modalMember.style.display = "none";
    },
    goBackPage() {
      if (this.apiId) {
        this.$router.push("/sysOps/apiManageDetail/" + this.apiId);
      } else {
        this.$router.push("/sysOps/apiManageList");
      }
    },
    // 등록 / 수정
    async apiCheck() {
      this.resetBlank();
      return await new Promise((resolve) => {
        document
          .querySelector(".data_val_tbl.active")
          .querySelectorAll(".data_name")
          .forEach(function (e) {
            if (!e.value || e.value == null || e.value == "") {
              e.parentElement.classList.add("failure");
              e.parentElement.nextElementSibling.style.display = "block";
              resolve(false);
            }
          });
        document
          .querySelector(".data_val_tbl.active")
          .querySelectorAll(".data_path")
          .forEach(function (e) {
            if (!e.value || e.value == null || e.value == "") {
              e.parentElement.classList.add("failure");
              e.parentElement.nextElementSibling.style.display = "block";
              resolve(false);
            }
          });
        resolve(true);
      });
    },
    async registData() {
      const apiCategory = this.apiCategory;
      const data = [];

      document
        .querySelector(".data_val_tbl.active")
        .querySelectorAll(".data_val_tbl_data")
        .forEach(function (e) {
          const inData = {};
          if (apiCategory != "003") {
            inData.funcName = e.querySelector(".data_name").value;
            inData.method = e.querySelector(".data_path").value;
            inData.billingYn = e.querySelector(".data_fee").value;
            inData.parameter = [];
            e.querySelectorAll(".data_param").forEach(function (e) {
              inData.parameter.push(e.value);
            });

            inData.parameter = JSON.stringify(inData.parameter);
          } else {
            inData.funcName = e.querySelector(".data_name").value;
            inData.protocol = e.querySelector(".data_def").value;
            inData.msg = e.querySelector(".data_param").value;
            inData.billingYn = e.querySelector(".data_fee").value;
          }

          data.push(inData);
        });

      this.formData.name = this.apiNm;
      this.formData.url = this.apiUrl;
      this.formData.projectId = this.prjId;
      this.formData.type = this.apiCategory;
      //this.formData.description = "일이삼";
      //this.formData.loggerUrl = "https://www.naver.com";
      if (apiCategory != "003") {
        this.formData.rest = data;
        this.formData.webSocket = [];
        const webSocketObject = {
          funcName: "",
          protocol: "",
          msg: "",
          billingYn: "",
        };
        this.formData.webSocket.push(webSocketObject);
      } else {
        this.formData.webSocket = data;
        this.formData.rest = [];
        const restObject = {
          funcName: "",
          method: "",
          parameter: "",
          billingYn: "",
        };
        this.formData.rest.push(restObject);
      }

      console.log(this.formData);

      try {
        let response;

        if (this.apiId && this.apiId != null && this.apiId != "") {
          response = await common.apiPatch(
            "/api-mng/" + this.apiId,
            this.formData
          );
        } else {
          response = await common.apiPost("/api-mng", this.formData);
        }

        if (response.status === 200 || response.status === "200") {
          this.$router.push("/sysOps/apiManageList");
        }
      } catch (error) {
        console.error("Error posting data:", error);
      }
    },
    resetBlank() {
      document.querySelectorAll(".data_name").forEach(function (e) {
        e.parentElement.classList.remove("failure");
      });
      document.querySelectorAll(".data_path").forEach(function (e) {
        e.parentElement.classList.remove("failure");
      });
      document
        .querySelector(".data_val_tbl.active")
        .querySelectorAll(".text_message")
        .forEach(function (e) {
          e.style.display = "none";
        });
    },
    async clickRegist() {
      if (!this.apiNm || this.apiNm == null || this.apiNm == "") {
        this.isEmptyApiNm = true;
      } else {
        this.isEmptyApiNm = false;
      }

      if (!this.apiUrl || this.apiUrl == null || this.apiUrl == "") {
        this.isEmptyApiUrl = true;
      } else {
        this.isEmptyApiUrl = false;
      }
      if (!this.prjId || this.prjId == null || this.prjId == "") {
        this.isEmptyPrjId = true;
      } else {
        this.isEmptyPrjId = false;
      }

      this.apiCheck().then((res) => {
        if (res != false) {
          this.registData();
        }
      });
    },
    chgApiCategory(e) {
      this.apiCategoryNm = e.target.options[e.target.selectedIndex].text;
      this.apiCategory = e.target.options[e.target.selectedIndex].value;
      this.chgApiCategoryTbl();
    },
    chgApiCategoryTbl() {
      document.querySelectorAll(".data_val_tbl").forEach(function (e) {
        e.classList.remove("active");
      });

      if (this.apiCategory == "001") {
        this.$refs.apiCategoryREST.classList.add("active");
        this.$refs.apiCategoryREST.style.display = "table";
        this.$refs.apiCategoryHTTP.style.display = "none";
        this.$refs.apiCategoryWebsocket.style.display = "none";
      } else if (this.apiCategory == "002") {
        this.$refs.apiCategoryHTTP.classList.add("active");
        this.$refs.apiCategoryHTTP.style.display = "table";
        this.$refs.apiCategoryREST.style.display = "none";
        this.$refs.apiCategoryWebsocket.style.display = "none";
      } else if (this.apiCategory == "003") {
        this.$refs.apiCategoryWebsocket.classList.add("active");
        this.$refs.apiCategoryWebsocket.style.display = "table";
        this.$refs.apiCategoryHTTP.style.display = "none";
        this.$refs.apiCategoryREST.style.display = "none";
      }
    },
    addParam(e) {
      const $div = document.createElement("div");
      $div.classList.add("data_val_btn_wrap");
      $div.innerHTML =
        "<div class='data_val'>" +
        "    <input type='text' maxlength='50' placeholder='입력해주세요.' class='data_param' style='padding-right:50px'>" +
        "</div>" +
        "<div class='data_val_btn'>" +
        "    <button class='btn_add_item add_param'>Parameter 추가</button>" +
        "    <button type='button' class='save_box remove_param'>삭제</button>" +
        "</div>";
      e.target.parentElement.parentElement.parentElement.appendChild($div);

      e.target.parentElement.parentElement.nextElementSibling
        .querySelector(".add_param")
        .addEventListener("click", this.addParam);

      e.target.parentElement.parentElement.nextElementSibling
        .querySelector(".remove_param")
        .addEventListener("click", this.removeParam);
      e.target.style.display = "none";
      const length = document.querySelectorAll(".data_val_btn_wrap").length;
      if (length > 1) {
        e.target.nextElementSibling.style.display = "inline-block";
      }
    },
    removeParam(e) {
      /*
      const $button = document.createElement("button");
      $button.classList.add("btn_add_item");
      $button.classList.add("add_param");
      $button.innerText = "Parameter 추가";
      */
      const newTarget = e.target.parentElement.parentElement.parentElement;
      if (
        e.target.parentElement.parentElement ==
        e.target.parentElement.parentElement.parentElement.lastElementChild
      ) {
        if (e.target.parentElement.parentElement.previousElementSibling) {
          e.target.parentElement.parentElement.previousElementSibling.querySelector(
            ".add_param"
          ).style.display = "inline-block";
        }
      }

      const length =
        e.target.parentElement.parentElement.parentElement.querySelectorAll(
          ".data_val_btn_wrap"
        ).length;

      if (length > 1) {
        e.target.parentElement.parentElement.remove();
      }
      if (length == 2) {
        newTarget
          .querySelector(".data_val_btn_wrap")
          .querySelector(".remove_param").style.display = "none";
      }
    },
    addLine(e) {
      const $apiCategory = this.$refs.apiCategory.value;
      const $div = document.createElement("div");
      $div.classList.add("data_val_tbl_row");
      $div.classList.add("data_val_tbl_data");
      $div.innerHTML =
        "<div class='data_val_tbl_row_data' style='min-height:100px'>" +
        "    <div class='data_val_wrap_1'>" +
        "        <div class='data_val'>" +
        "            <input type='text' maxlength='50' placeholder='입력해주세요.' class='data_name' style='padding-right:50px'>" +
        "        </div>" +
        "        <div class='text_message' style='display:none; position:absolute; margin-top:65px; margin-left:10px text-align:left; font-size:13px; color:#F4514D;'>Method Name을 입력해 주세요</div>" +
        "    </div>" +
        "    <div class='data_val_wrap_2'>" +
        "        <div class='data_val'>" +
        "            <input type='text' maxlength='10' placeholder='입력해주세요.' class='data_path' style='padding-right:50px'>" +
        "        </div>" +
        "        <div class='text_message' style='display:none; position:absolute; margin-top:65px; margin-left:10px text-align:left; font-size:13px; color:#F4514D;'>Method를 입력해 주세요</div>" +
        "    </div>" +
        "    <div class='data_val_wrap_3'>" +
        "        <div class='data_val_btn_wrap'>" +
        "            <div class='data_val'>" +
        "                <input type='text' maxlength='50' placeholder='입력해주세요.' class='data_param' style='padding-right:50px'>" +
        "            </div>" +
        "            <div class='data_val_btn'></div>" +
        "        </div>" +
        "    </div>" +
        "</div>" +
        "<div class='data_val_tbl_row_btn'>" +
        "    <button type='button' class='btn_add_item add_line'>Method 추가</button>" +
        "    <button type='button' class='save_box remove_line'>삭제</button>" +
        "    <div class='add_fee'>" +
        "        <div class='data_key'>과금 유무</div>" +
        "        <div class='data_val'>" +
        "           <select class='data_fee'>" +
        "             <option value='N'>N</option>" +
        "             <option value='Y'>Y</option>" +
        "           </select>" +
        "        </div>" +
        "    </div>" +
        "</div>";
      e.target.nextElementSibling.style.display = "inline-block";
      e.target.parentElement.parentElement.parentElement.appendChild($div);
      if ($apiCategory != 3) {
        const $button =
          "<button class='btn_add_item add_param'>Parameter 추가</button>" +
          "<button type='button' class='save_box remove_param' style='display:none'>삭제</button>";
        e.target.parentElement.parentElement.nextElementSibling.querySelector(
          ".data_val_btn"
        ).innerHTML = $button;
        e.target.parentElement.parentElement.parentElement.lastElementChild
          .querySelector(".add_param")
          .addEventListener("click", this.addParam);
        e.target.parentElement.parentElement.parentElement.lastElementChild
          .querySelector(".remove_param")
          .addEventListener("click", this.removeParam);
      } else {
        const $select =
          "<select class='data_def'>" +
          "    <option value='1'>$connect</option>" +
          "    <option value='2'>$disconnect</option>" +
          "    <option value='3'>$default</option>" +
          "    <option value='3'>$custom</option>" +
          "</select>";
        e.target.parentElement.parentElement.nextElementSibling
          .querySelector(".data_val_tbl_row_data")
          .querySelector(".data_val_wrap_2")
          .querySelector(".data_val").innerHTML = $select;
      }
      e.target.parentElement.parentElement.parentElement.lastElementChild
        .querySelector(".add_line")
        .addEventListener("click", this.addLine);
      e.target.parentElement.parentElement.parentElement.lastElementChild
        .querySelector(".remove_line")
        .addEventListener("click", this.removeLine);
      e.target.style.display = "none";
    },
    removeLine(e) {
      if (
        e.target.parentElement.parentElement ==
        e.target.parentElement.parentElement.parentElement.lastElementChild
      ) {
        if (e.target.parentElement.parentElement.previousElementSibling) {
          e.target.parentElement.parentElement.previousElementSibling.querySelector(
            ".add_line"
          ).style.display = "inline-block";
        }
      }
      const $element = e.target.parentElement.parentElement.parentElement;
      const $length = $element.querySelectorAll(".data_val_tbl_row").length;

      if ($length > 2) {
        e.target.parentElement.parentElement.remove();
      }

      if ($length == 3) {
        $element
          .querySelectorAll(".data_val_tbl_row")[1]
          .lastElementChild.querySelector(".remove_line").style.display =
          "none";
      }
    },
    async setRole() {
      // this.manageRole = await common.getUserRole('type3');
      this.manageRole = true;
      // this.viewRole = await common.getUserRole('type2');
      this.viewRole = true;
    },
    clickTogglePrj() {
      const toggle = !this.prjInfoToggle;
      this.prjInfoToggle = toggle;
    },
    evtBox(e) {
      const btn = e.target;
      if (btn.classList.contains("active")) {
        btn.classList.remove("active");
        btn.nextElementSibling.style.display = "none";
      } else {
        btn.classList.add("active");
        btn.nextElementSibling.style.display = "block";
      }
    },
    //공유하기 버튼 클릭시 url 복사
    async clipCopy() {
      let dummy = document.createElement("textarea");
      document.body.appendChild(dummy);

      try {
        const url = window.document.location.href;
        dummy.value = url;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        alert("url copy success");
      } catch (error) {
        console.log(error);
      }
    },
    // URL 유효성 검사
    isValidURL(url) {
      const urlRegex =
        //eslint-disable-next-line
        /^(https?|ftp):\/\/(-\.)?([^\s\/?\.#-]+\.?)+(\/[^\s]*)?$/i;
      return urlRegex.test(url);
    },

    setPrjId(event) {
      this.prjId = event.target.value;
    },
    async setPrjSelect() {
      this.prjList = await this.getPrjListData();
    },
    // API
    async getPrjData(prjId) {
      try {
        const response = await common.apiGet("/project/" + prjId);
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getPrjListData() {
      try {
        const response = await common.apiGet(
          "/project?project-role=Researcher"
        );
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async setApiDetail(apiId) {
      try {
        const response = await common.apiGet("api-mng/" + apiId);
        this.apiData = response.data.data;
        this.apiCategory = this.apiData.type;
        this.chgApiCategoryTbl();
        if (this.apiData != null) this.dataSetting();
      } catch (error) {
        console.error("error", error);
      }
    },
    dataSetting() {
      this.apiNm = this.apiData.name;
      this.apiUrl = this.apiData.url;
      this.apiLogger = this.apiData.loggerUrl;

      if (this.apiCategory == "001") {
        this.restList = this.apiData.rest;
        for (let item of this.restList) {
          item.parameter = JSON.parse(item.parameter);
        }
      } else if (this.apiCategory == "002") {
        this.httpList = this.apiData.rest;
        for (let item of this.httpList) {
          item.parameter = JSON.parse(item.parameter);
        }
      } else if (this.apiCategory == "003") {
        this.webSocketList = this.apiData.webSocket;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.list_wrap {
  position: relative;

  .list_filter_wrap {
    position: absolute;
    top: -90px;
  }
}
.item_info .item_table table th.before_left_border {
  vertical-align: middle;
}
.item_info .item_table table th.before_left_border::before {
  content: "";
  display: block;
  width: 1px;
  height: 15px;
  background-color: #e5e9ef;
  position: absolute;
  bottom: 16px;
  left: 0;
}
.list_wrap .table_list .mando_table thead th .col_name {
  // min-width: auto !important;
  padding-right: 15px;
}

.data_val_tbl {
  .data_val {
    &.failure {
      & ~ .text_message {
        position: absolute;
        margin-top: 65px;
        margin-left: 10px;
        text-align: left;
        font-size: $fontSub;
        color: $failedColor;
      }
    }
  }
}
</style>
