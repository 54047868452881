<template>
  <HeaderLayout />
  <GlobalNavBar />
  <div id="container">
    <div id="contents">
      <!-- <div class="title_wrap">
                <div class="breadcrumb">
                    <span>데이터/ TASK 운영</span>
                    <span>차량 모니터링 목록</span>
                    <span class="active">Motor Output Anomaly Detection 상세</span>
                </div>
                <h2>[TASK_AD] {{taskData.taskName}}</h2>
            </div> -->
      <BreadcrumbLayout
        pageId="monTASK_drivingDataManagement"
        :name="'[TASK_AD] ' + taskData.taskName"
      />
      <div class="item_info">
        <div class="item_title">
          <strong class="task_project"
            >[PROJECT] {{ prjData.project_name }}</strong
          >
          <div class="item_info_btn_box">
            <button type="button" class="btn_fold" @click="hideProjectInfo">
              {{ foldStatus }}
            </button>
            <button
              type="button"
              class="btn_evt_group"
              @click="hideUtils"
            ></button>
            <ul
              class="evt_btn_box radiusbox"
              :style="{ display: InferenceUtil ? 'block' : 'none' }"
            >
              <li class="download_item">
                <button type="button" @click="makePDF">데이터 다운로드</button>
              </li>
              <li class="share_item">
                <button type="button" @click="clipCoppy">공유하기</button>
              </li>
              <li class="retouch_item" v-if="manageRole">
                <button type="button" @click="taskModify">TASK 수정</button>
              </li>
            </ul>
          </div>
        </div>
        <div class="item_table radiusbox" v-if="visibleProjectInfo">
          <table>
            <thead>
              <tr>
                <th>Project ID</th>
                <th>AWS ID</th>
                <th>PM</th>
                <th>Project Type</th>
                <th>Unit</th>
                <th>Last Activity</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ prjData.project_name }}</td>
                <td>{{ prjData.aws_id }}</td>
                <td>{{ prjData.pm }}</td>
                <td>{{ prjData.project_type }}</td>
                <td>{{ prjData.team_name }}</td>
                <td>{{ prjData.last_activity }}</td>
              </tr>
            </tbody>
          </table>
          <div class="item_desc">
            <div class="desc_title">Description</div>
            <div class="desc_txt">{{ prjData.project_description }}</div>
          </div>
        </div>
      </div>
      <div id="monCont" class="moa_dete">
        <div class="filter_box">
          <!-- selectBox -->
          <div class="select_box">
            <select name="a" v-model="oemSelect">
              <option value="0">OEM 선택</option>
              <option v-for="oem in oemOption" :key="oem.id" :value="oem.id">
                {{ oem.value }}
              </option>
            </select>
            <select name="a" v-model="vehicleSelect">
              <option value="0">Vehicle 선택</option>
              <option
                v-for="vehicle in vehicleOption"
                :key="vehicle.id"
                :value="vehicle.id"
              >
                {{ vehicle.value }}
              </option>
            </select>
            <select name="a" v-model="edgeSelect">
              <option value="0">Edge ID 선택</option>
              <option
                v-for="edge in edgeOption"
                :key="edge.id"
                :value="edge.id"
              >
                {{ edge.value }}
              </option>
              <option value="1">테스트 Edge 1</option>
              <option value="2">테스트 Edge 2</option>
            </select>
          </div>
          <!-- selectBox -->

          <!-- date filter -->
          <div class="date_filter">
            <select name="a" v-model="carSelect" style="width: 120px">
              <option value="0">차량 선택</option>
              <option
                v-for="car in carOption"
                :key="car.car_number"
                :value="car.car_number"
              >
                {{ car.car_number }}
              </option>
            </select>
            <div class="date_period">
              <div class="date_box">
                <span
                  @click="dateAction('S')"
                  style="
                    display: inline-block;
                    cursor: pointer;
                    padding: 0 10px 0 10px;
                  "
                  :style="picStartTxtStyle"
                  ref="picStartTxt"
                  >{{ picStartDate }}</span
                >
                ~
                <span
                  @click="dateAction('E')"
                  style="
                    display: inline-block;
                    cursor: pointer;
                    padding: 0 0 0 10px;
                  "
                  :style="picEndTxtStyle"
                  ref="picEndTxt"
                  >{{ picEndDate }}</span
                >
              </div>
              <button type="button" ref="datePicToggle"></button>
              <div
                class="calender_box radiusbox"
                ref="calenderBox"
                style="display: none"
              >
                <div
                  class="start_date date_box"
                  ref="startDateBox"
                  style="display: none"
                >
                  <VCalendarUi
                    :dateInterface="'start'"
                    :setDate="'7'"
                    :maxDate="picEndDate"
                    v-model="picStartDateCalendar"
                    @dayclick="onDatePic('S', picStartDateCalendar)"
                  />
                </div>
                <div
                  class="end_date date_box"
                  ref="endDateBox"
                  style="display: none"
                >
                  <VCalendarUi
                    :dateInterface="'end'"
                    :minDate="picStartDate"
                    v-model="picEndDateCalendar"
                    @dayclick="onDatePic('E', picEndDateCalendar)"
                  />
                </div>
              </div>
            </div>
            <div class="search_box">
              <input
                type="text"
                v-model="searchKeyword"
                ref="searchKeyword"
                placeholder="검색어를 입력해 주세요."
              />
            </div>
            <button
              type="button"
              class="btn_send mcbtn"
              @click="apiCallMando()"
            >
              조회
            </button>
          </div>
          <!-- date filter -->
        </div>
        <div class="item_info">
          <div class="float_box">
            <div class="log_filter radiusbox">
              <div class="driving_record_list">
                <template v-if="carDataList.length > 0">
                  <ul>
                    <li
                      class="data_list"
                      :class="selectTab === data.car_number ? 'active' : ''"
                      v-for="data in carDataList"
                      :key="data.car_number"
                      @click="carDetail(data.car_number)"
                    >
                      <div class="car_driver_wrap">
                        <div class="car">
                          <div class="icon">
                            <font-awesome-icon :icon="['fas', 'car-side']" />
                          </div>
                          <div class="txt">{{ data.car_number }}</div>
                        </div>
                        <div class="driver">
                          <div class="icon">
                            <font-awesome-icon :icon="['fas', 'user']" />
                          </div>
                          <div class="txt">({{ data.driver }})</div>
                        </div>
                      </div>
                      <div>{{ data.logging_date }}</div>
                    </li>
                  </ul>
                </template>
                <template v-else>
                  <p class="carDataEmpty">데이터가 없습니다.</p>
                </template>
              </div>
            </div>
            <div class="log_data_box">
              <div class="result_box radiusbox">
                <div class="title_box">
                  <strong>기본 정보</strong>
                </div>
                <template v-if="carDataDetail && carDataDetail != null">
                  <div class="mando_table_wrap">
                    <table class="mando_table">
                      <thead>
                        <tr>
                          <th>차종</th>
                          <th>차량번호</th>
                          <th>운전자</th>
                          <th>로깅일자</th>
                          <!-- <th>주행시간</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ carDataDetail.car_type }}</td>
                          <td>{{ carDataDetail.car_number }}</td>
                          <td>{{ carDataDetail.driver }}</td>
                          <td>{{ carDataDetail.logging_date }}</td>
                          <!-- <td>?</td> -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </template>
                <template v-else>
                  <p
                    style="
                      text-align: center;
                      margin-top: 28px;
                      font-weight: 800;
                    "
                  >
                    데이터가 없습니다.
                  </p>
                </template>
              </div>
              <div
                class="result_box radiusbox"
                style="margin-top: 20px; height: auto; min-height: 314px"
              >
                <div class="title_box">
                  <strong>데이터</strong>
                </div>
                <template v-if="carDataDetailList.length > 0">
                  <div class="mando_table_wrap">
                    <table class="mando_table data_table">
                      <thead>
                        <tr>
                          <th>Data Category</th>
                          <th>구분</th>
                          <th>MCSP 호환</th>
                          <th>용량</th>
                          <th>다운로드</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template
                          v-for="(data, index) in carDataDetailList"
                          :key="index"
                        >
                          <tr>
                            <td
                              rowspan="2"
                              style="border-right: 1px solid #e5e9ef"
                            >
                              {{ data.data_category }}
                            </td>
                            <td>
                              {{
                                data.data_address_origin
                                  .substring(
                                    data.data_address_origin.lastIndexOf(".") +
                                      1,
                                    data.data_address_origin.length
                                  )
                                  .toUpperCase()
                              }}
                            </td>
                            <template v-if="data.data_use_mcsp_origin === 'X'">
                              <td>
                                <div class="icon icon-xmark">
                                  <font-awesome-icon
                                    :icon="['fas', 'circle-xmark']"
                                  />
                                </div>
                              </td>
                            </template>
                            <template v-else>
                              <td>
                                <div class="icon icon-check">
                                  <font-awesome-icon
                                    :icon="['fas', 'circle-check']"
                                  />
                                </div>
                              </td>
                            </template>

                            <td>{{ data.data_size_origin }} MB</td>
                            <td>
                              <a
                                :href="data.data_address_origin"
                                target="_blank"
                                class="btn_download mcbtn"
                                ><font-awesome-icon :icon="['fas', 'download']"
                              /></a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {{
                                data.data_address_trans
                                  .substring(
                                    data.data_address_trans.lastIndexOf(".") +
                                      1,
                                    data.data_address_trans.length
                                  )
                                  .toUpperCase()
                              }}
                            </td>

                            <template v-if="data.data_use_mcsp_trans === 'X'">
                              <div class="icon icon-xmark">
                                <font-awesome-icon
                                  :icon="['fas', 'circle-xmark']"
                                />
                              </div>
                            </template>
                            <template v-else>
                              <td>
                                <div class="icon icon-check">
                                  <font-awesome-icon
                                    :icon="['fas', 'circle-check']"
                                  />
                                </div>
                              </td>
                            </template>
                            <td>{{ data.data_size_trans }} MB</td>
                            <td>
                              <a
                                :href="data.data_address_trans"
                                target="_blank"
                                class="btn_download mcbtn"
                                ><font-awesome-icon :icon="['fas', 'download']"
                              /></a>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                    <div class="list_wrap" style="margin-top: -10px">
                      <div class="table_list">
                        <div class="list_table_footer">
                          <PaginationUi
                            :totalItems="totalItems"
                            :itemsPerPage="itemsPerPage"
                            @page-changed="onPageChanged"
                          ></PaginationUi>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <p
                    clas="carDataEmpty"
                    style="
                      width: 100%;
                      height: 100%;
                      text-align: center;
                      margin-top: 60px;
                      font-weight: 800;
                    "
                  >
                    데이터가 없습니다.
                  </p>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="lodingWrap" style="display: none" ref="lodingWrap">
    <div class="loading-container">
      <div class="loding-animation-holder">
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="middle-circle"></div>
      </div>
    </div>
  </div>
  <FooterLayout />
</template>

<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";
import VCalendarUi from "@/components/VCalendarUi_NEW.vue";
import PaginationUi from "@/components/PagenationUi.vue";
import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";

import common from "@/assets/js/common";
import apiCallVectorLogger from "@/assets/js/apiCallVectorLogger";

export default {
  name: "DrivingDataManagementView",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    VCalendarUi,
    PaginationUi,
    BreadcrumbLayout,
  },
  data() {
    return {
      // project , task 정보
      taskData: {
        projectId: null,
        projectName: null,
        awsId: null,
        taskName: null,
        creatorName: null,
        type: null,
        teamName: null,
        description: null,
      },

      // OEM 선택, Vehicle 선택, Edge ID 선택
      oemSelect: "0",
      vehicleSelect: "0",
      edgeSelect: "0",
      edgeSelectName: "",

      carSelect: "0",

      oemId: 0,
      vehicleId: 0,
      edgeId: 0,

      carNumber: 0,
      carDataNumber: "",

      oemOption: [],
      vehicleOption: [],
      edgeOption: [],
      carOption: [],

      // 기간선택
      selectDuration: 7,

      // VCalendarUi / .date_period

      picColor: {
        startStatus: false,
        endStatus: false,
        active: "#00B4ED",
        default: "#020A0A",
      },
      picStartDateModal: "",
      picEndDateModal: "",
      picColorModal: {
        startStatus: false,
        endStatus: false,
        active: "#00B4ED",
        default: "#020A0A",
      },

      selectDate: "1",

      //DTC Map

      foldStatus: "접기",
      InferenceUtil: false,

      visibleProjectInfo: true,

      prjData: {
        project_name: "",
        aws_id: "",
        pm: "",
        project_type: "",
        team_name: "",
        last_activity: "",
        project_description: "",
      },

      projectId: "",

      // role
      viewRole: false,
      manageRole: false,

      carDataList: [],
      carDataDetail: null,

      carDataDetailList: [],

      picStartDate: null,
      picEndDate: null,
      picStartDateCalendar: null,
      picEndDateCalendar: null,

      searchKeyword: "",

      totalItems: 0,
      itemsPerPage: 10,
      listOffset: 0,
      currentPage: 1,

      selectTab: 0,
    };
  },

  computed: {
    picStartTxtStyle() {
      return {
        color: this.picColor.startStatus
          ? this.picColor.active
          : this.picColor.default,
      };
    },
    picEndTxtStyle() {
      return {
        color: this.picColor.endStatus
          ? this.picColor.active
          : this.picColor.default,
      };
    },
    picStartTxtStyleModal() {
      return {
        color: this.picColorModal.startStatus
          ? this.picColorModal.active
          : this.picColorModal.default,
      };
    },
    picEndTxtStyleModal() {
      return {
        color: this.picColorModal.endStatus
          ? this.picColorModal.active
          : this.picColorModal.default,
      };
    },
  },
  async mounted() {
    // Role
    await this.setRole();

    this.setDate(this.selectDuration);

    // prj info
    const prjId = this.$route.query.prjId;
    const prjData = await this.getPrjData(prjId);
    if (typeof prjData !== "undefined" && prjData !== null && prjData !== "") {
      this.prjData = prjData.project;
    }

    try {
      // monitoring-task 정보
      await common
        .apiGet(
          "/monitoring-task/" +
            this.$route.query.taskId +
            "?viewRole=" +
            this.viewRole
        )
        .then((r) => {
          console.log("0. monitoring-task 정보");
          console.log(r.data.data);
          this.taskData.projectId = r.data.data.projectId;
          this.taskData.projectName = r.data.data.projectName;
          this.taskData.awsId = r.data.data.awsId;
          this.taskData.taskName = r.data.data.taskName;
          this.taskData.creatorName = r.data.data.creatorName;
          this.taskData.type = r.data.data.type;
          this.taskData.teamName = r.data.data.teamName;
          this.taskData.lastVisit = r.data.data.lastVisit;
          this.taskData.description = r.data.data.description;
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    try {
      // monitoring-task 에 속한 edge-device 정보
      await common
        .apiGet(
          "/edge-device/monitoring-task/edge-device-group/" +
            this.$route.query.taskId
        )
        .then((r) => {
          console.log("0. monitoring-task 에 속한 edge-device 정보");
          console.log(r.data.data);
          const o = [];
          r.data.data.forEach((e) => {
            if (!o.some((item) => item.id === e.id)) {
              o.push({
                id: e.id,
                value: e.edge_device_id,
                oem_id: e.oem_id,
                vehicle_id: e.vehicle_id,
              });
            }
          });
          this.edgeOption = o; // edge 선택 option
          this.edgeSelect = this.edgeOption[0].id; // 첫번째 선택
          this.edgeSelectName = this.edgeOption[0].value;
          this.oemSelect = this.edgeOption[0].oem_id; // 첫번째 oem 선택
          this.vehicleSelect = this.edgeOption[0].vehicle_id; // 첫번째 vehicle 선택

          const edgeIds = [];
          this.edgeOption.forEach((e) => {
            edgeIds.push(e.id);
          });
          // 테스트용 ID //
          edgeIds.push(1);
          edgeIds.push(2);
          // --테스트용 ID-- //
          //console.log(edgeIds);
          this.callCarApi(edgeIds);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    // mando api call
    await this.apiCallMando();

    //projectId
    this.projectId = this.$route.query.prjId;
    // await this.allowAccess();
  },
  watch: {
    oemSelect() {
      if (this.oemSelect == "0") this.oemId = "";
      else this.oemId = this.oemSelect;
      this.getVehicleSelect(this.oemSelect);
    },
    vehicleSelect() {
      if (this.vehicleSelect == "0") this.vehicleId = "";
      else this.vehicleId = this.vehicleSelect;
    },
    edgeSelect() {
      if (this.edgeSelect == "0") this.edgeId = "";
      else this.edgeId = this.edgeSelect;
    },
    carSelect() {
      if (this.carSelect == "0") this.carNumber = "";
      else this.carNumber = this.carSelect;
    },
    selectDuration() {
      this.setDate(this.selectDuration);
    },
  },
  methods: {
    carDetail(carNumber) {
      this.selectTab = carNumber;
      this.carDataDetailList = [];
      this.carDataDetail = {};
      this.carDataList.forEach((e) => {
        if (e.car_number == carNumber) {
          this.carDataDetail.car_number = e.car_number;
          this.carDataNumber = e.car_number;
          this.carDataDetail.car_type = e.car_type;
          this.carDataDetail.driver = e.driver;
          this.carDataDetail.logging_date = e.logging_date;

          this.carDataDetailList = e.items;
        }
      });
      console.log(".//////////// :: ", this.carDataDetailList);
    },
    async callCarApi(edgeIds) {
      console.log("신규 API 결과 ::===============");
      try {
        const param = {
          edgeIds: edgeIds,
        };
        const response = await apiCallVectorLogger.post(
          "/vl/vl_car_list/",
          param
        );
        console.log("신규 API 결과 :: ", response);
        if (response.status === 200 || response.status === "200") {
          this.carOption = response.data.car_numbers;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getCarLogger() {
      const keyword = this.searchKeyword;
      const start = this.picStartDate;
      const end = this.picEndDate;
      const offset = (this.currentPage - 1) * this.itemsPerPage;
      const limit = this.itemsPerPage;
      //console.log("limit :: ", limit);
      console.log("offset :: ", offset);
      console.log("limit !!! :: ", limit);

      try {
        const param = {
          car_number: "0776",
          keyword: keyword,
          start: start,
          end: end,
          offset: offset,
          limit: limit,
        };
        const response = await apiCallVectorLogger.post("/vl/vl_items/", param);
        if (response.status === 200 || response.status === "200") {
          this.totalItems = response.data.total;
          this.listOffset = response.data.current_offset;
          //this.carDataDetailList = response.data.can_items;
          this.carDataList = response.data.can_items;
        }
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },
    async setRole() {
      const prjId = this.$route.query.prjId;
      this.manageRole =
        (await common.getUserRole("type2")) ||
        (await common.getPrjRole("type2", prjId));
      this.viewRole =
        (await common.getUserRole("type2")) ||
        (await common.getPrjRole("type1", prjId));

      if (!this.viewRole) {
        common.goHome();
      }
    },
    async onPageChanged(page) {
      this.currentPage = page;
      await this.getCarLogger();
      this.carDetail(this.carDataNumber);
    },

    /**************
     * select box
     **************/
    async getOemSelect() {
      try {
        const response = await common.apiGet(
          "/edge-device-group/domain/oem/vehicle/1"
        );
        const o = [];
        response.data.data.forEach((e) => {
          o.push({ id: e.id, value: e.name });
        });
        this.oemOption = o;
      } catch (error) {
        console.log("Fail: /edge-device-group/domain/oem/vehicle/1");
      }
    },
    async getVehicleSelect(id) {
      try {
        const o = [];
        if (id != 0) {
          const response = await common.apiGet(
            "/edge-device-group/domain/oem/vehicle/" + id
          );
          response.data.data.forEach((e) => {
            o.push({ id: e.id, value: e.name });
          });
        }
        this.vehicleOption = o;
      } catch (error) {
        console.log("Fail: /edge-device-group/domain/oem/vehicle/" + id);
      }
    },

    async apiCallMando() {
      // init
      //this.initAnomaly();

      const vm = this;

      // const $drivReclistBox = vm.$refs.drivReclistBox;

      // $drivReclistBox.style.display = 'block';

      // 로딩 시작
      vm.$refs.lodingWrap.style.display = "block";

      // OEM 선택
      await this.getOemSelect();

      if (this.carSelect != 0) {
        await this.getCarLogger();
      }

      // 로딩 시작 끝
      vm.$refs.lodingWrap.style.display = "none";
    },

    onDatePic(flag, targetDay) {
      const year = targetDay.getFullYear();
      const month = targetDay.getMonth() + 1;
      const day = targetDay.getDate();
      const formattedDate =
        year +
        "-" +
        (month < 10 ? "0" : "") +
        month +
        "-" +
        (day < 10 ? "0" : "") +
        day;

      if (flag === "S") {
        this.picStartDate = formattedDate;
        this.$refs.startDateBox.style.display = "none";
        this.picColor.startStatus = false;
      } else if (flag === "E") {
        this.picEndDate = formattedDate;
        this.$refs.endDateBox.style.display = "none";
        this.picColor.endStatus = false;
      }
    },

    setDate(day) {
      const vm = this;
      const stoday = new Date();
      stoday.setDate(stoday.getDate() - day);
      const syear = stoday.getFullYear();
      const smonth = stoday.getMonth() + 1;
      const sday = stoday.getDate();
      const start_formattedDate =
        syear +
        "-" +
        (smonth < 10 ? "0" : "") +
        smonth +
        "-" +
        (sday < 10 ? "0" : "") +
        sday;

      const etoday = new Date();
      const eyear = etoday.getFullYear();
      const emonth = etoday.getMonth() + 1;
      const eday = etoday.getDate();
      const end_formattedDate =
        eyear +
        "-" +
        (emonth < 10 ? "0" : "") +
        emonth +
        "-" +
        (eday < 10 ? "0" : "") +
        eday;
      // console.log(start_formattedDate + ' ~ ' + end_formattedDate);
      vm.picStartDateCalendar = new Date(start_formattedDate);
      vm.picEndDateCalendar = new Date(end_formattedDate);
      vm.picStartDate = start_formattedDate;
      vm.picEndDate = end_formattedDate;
      window.selectDate.startDate = start_formattedDate;
      window.selectDate.endDate = end_formattedDate;

      this.selectDate = day;
    },
    // -------- DATE PICKER END -----

    nowTime() {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    // 프로젝트정보 , 부가기능
    async hideProjectInfo() {
      // 프로젝트 정보 - 접기 , 펼치기
      if (this.visibleProjectInfo == true) {
        this.visibleProjectInfo = false;
        this.foldStatus = "펼치기";
      } else {
        this.visibleProjectInfo = true;
        this.foldStatus = "접기";
      }
    },
    hideUtils() {
      // 부가기능 - 보이기 숨기기
      if (this.InferenceUtil == true) {
        this.InferenceUtil = false;
      } else if (this.InferenceUtil == false) {
        this.InferenceUtil = true;
      }
    },
    async clipCoppy() {
      // 공유하기
      let dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      try {
        const url = window.document.location.href;
        dummy.value = url;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        alert("url copy success");
      } catch (error) {
        console.error(error);
      }
    },
    async taskModify() {
      // 수정
      if (this.manageRole) {
        const taskId = this.$route.query.taskId;
        const prjId = this.$route.query.prjId;
        const taskTypeId = this.$route.query.taskTypeId;
        const query = {
          prjId: prjId,
          taskId: taskId,
          taskTypeId: taskTypeId,
        };
        // console.log(taskId);
        try {
          this.$router.push({
            name: "AddTask",
            query,
          });
        } catch (error) {
          console.error("Error:", error);
        }
      }
    },

    async getPrjData(prjId) {
      try {
        const response = await common.apiGet("/project/" + prjId);
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    //-------API------
    async getMemberRole() {
      let response;
      try {
        let url = `/member/check-role`;
        response = await common.apiGet(url);
        return response.data.data;
      } catch (error) {
        console.error("error", error);
        return null;
      }
    },
    async getProjectRole() {
      let response;
      try {
        let checkProjectRoleUrl = `/member/check-project-role/${this.projectId}`;
        response = await common.apiGet(checkProjectRoleUrl);
        return response.data.data;
      } catch (error) {
        console.error("error", error);
        return null;
      }
    },

    // -------- DATE PICKER -----
    dateAction(StartEnd) {
      if (StartEnd === "S") {
        const display = this.$refs.startDateBox.style.display;

        if (display === "none") {
          this.$refs.endDateBox.style.display = "none";
          this.$refs.startDateBox.style.display = "block";
          this.$refs.calenderBox.style.display = "block";
          this.picColor.startStatus = true;
          this.picColor.endStatus = false;
        } else {
          this.picColor.startStatus = false;
          this.$refs.startDateBox.style.display = "none";
          this.$refs.calenderBox.style.display = "none";
        }
      } else {
        const display = this.$refs.endDateBox.style.display;
        if (display === "none") {
          this.$refs.startDateBox.style.display = "none";
          this.$refs.endDateBox.style.display = "block";
          this.$refs.calenderBox.style.display = "block";
          this.picColor.startStatus = false;
          this.picColor.endStatus = true;
        } else {
          this.picColor.endStatus = false;
          this.$refs.endDateBox.style.display = "none";
          this.$refs.calenderBox.style.display = "none";
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.item_info {
  .item_title {
    .btn_fold {
      right: 90px;
    }
  }
}

.moa_dete {
  overflow: hidden;
  .filter_box {
    .select_box {
      float: left;
      select {
        width: 185px;
        background-position: right 10px center;
        &:last-child {
          margin: 0;
        }
      }
    }
    .date_filter {
      float: right;
      position: relative;
      & select {
        position: relative;
        background-position: right 10px center;
      }
      display: flex;
      align-items: center;
      .search_box {
        margin-right: 10px;
        input[type="text"] {
          width: 210px;
          border: 1px solid #d5dae3;
          background-color: #fff;
          border-radius: 10px;
          box-sizing: border-box;
          padding: 10px 15px;
          font-size: 15px;
          color: rgb(2, 10, 10);
        }
      }
    }
  }
  .float_box {
    width: 100%;
    float: left;
    margin-top: 20px;
    .log_filter {
      float: left;
      width: 300px;
      height: 100%;
      background-color: #fff;
      margin-right: 20px;
      box-sizing: border-box;
      position: relative;
    }
    .log_data_box {
      float: left;
      width: calc(100% - 320px);
      .mando_table_wrap {
        .mando_table {
          position: static;
          &.data_table {
            td {
              .icon {
                font-size: 22px;
                &.icon-xmark {
                  color: #f15046;
                }
                &.icon-check {
                  color: #02a744;
                }
              }
              padding: 12px 10px;
              a {
                font-weight: 800;
                &.btn_download {
                  padding: 8px;
                }
              }
            }
          }
        }
        .list_wrap {
          .table_list {
            box-shadow: none;
            .list_table_footer {
              box-shadow: none;
            }
          }
        }
      }
    }
  }

  .status_box {
    height: 144px;
    margin: 20px 0;
    & > div {
      height: 100%;
      float: left;
      position: relative;
      &.status {
        // width: 303px;
        width: 412px;
        button {
          width: 64px;
          height: 40px;
          box-sizing: border-box;
          border: 1px solid $disable02Color;
          border-radius: 10px;
          position: absolute;
          top: 15px;
          right: 15px;
        }
        &:hover {
          &.normal {
            button {
              background-color: $okColor;
              color: #fff;
              border: none;
            }
          }
          &.warning {
            button {
              background-color: #eabe6e;
              color: #fff;
              border: none;
            }
          }
          &.danger {
            button {
              background-color: $failedColor;
              color: #fff;
              border: none;
            }
          }
        }
        div {
          box-sizing: border-box;
          // padding: 40px 0 0 20px;
          padding: 40px 0 0 40px;
          span {
            color: #6e7380;
            display: block;
          }
          strong {
            display: block;
            font-size: 30px;
            line-height: 52px;
          }
        }
        &.normal {
          strong {
            color: $okColor;
          }
        }
        &.warning {
          strong {
            color: #eabe6e;
          }
        }
        &.danger {
          strong {
            color: $failedColor;
          }
        }
      }
      &.time_distance {
        // width: 630px;
        width: 412px;
        margin: 0 20px;
        box-sizing: border-box;
        padding: 30px 0;
        .float_box {
          & > div {
            width: 50%;
            float: left;
            box-sizing: border-box;
            padding: 10px 0 0 40px;
            span {
              color: #6e7380;
              display: block;
            }
            strong {
              font-size: 30px;
              line-height: 52px;
              font-family: $montserrat;
              font-weight: 500;
            }
            sup {
              font-family: $montserrat;
              display: inline-block;
              margin-left: 5px;
            }

            // &:last-child{
            //     border-left: 1px solid $line02Color;
            // }
          }
        }
      }
      &.update {
        // width: 303px;
        width: 412px;
        box-sizing: border-box;
        // padding: 20px 0 10px 15px;
        padding: 20px 0 10px 40px;
        strong {
          font-weight: $bold;
        }
        p {
          margin-top: 20px;
          margin-bottom: 20px;
        }
        .img_box {
          width: 14px;
          height: 14px;
          display: inline-block;
          position: relative;
          top: 3px;
          margin-right: 5px;
          background-image: url($baseURL + "/common/refresh_icon.svg");
          background-repeat: no-repeat;
          background-position: center;
        }
        span {
          color: $disable01Color;
          font-size: 13px;
        }
        .toggle_box {
          position: absolute;
          top: 15px;
          right: 20px;
        }
      }
    }
  }
  .result_box {
    width: 100%;
    height: 166px;
    .title_box {
      position: relative;
      padding-top: 20px;
      padding-left: 20px;
      padding-bottom: 15px;
      strong {
        font-weight: $bold;
      }
    }
    .mando_table {
      tr {
        &:hover {
          background: none;
        }
      }
      td {
        padding: 25px 20px;
      }
      .result {
        font-size: 25px;
        font-weight: 700;
        font-family: $montserrat;
        &.ok {
          color: $okColor;
        }
        &.ng {
          color: $failedColor;
        }
      }
    }
  }
  .sub_data_box {
    margin-top: 20px;
    & > div {
      width: 304px;
      height: 308px;
      float: left;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
      .title_box {
        position: relative;
        padding-top: 20px;
        padding-left: 20px;
        padding-bottom: 15px;
        strong {
          font-weight: $bold;
        }
      }
      &.anomaly_status {
        .float_box {
          width: 100%;
          height: 255px;
          box-sizing: border-box;
          padding: 0 10px 10px 10px;
          & > div {
            width: 137px;
            height: 119px;
            float: left;
            text-align: center;
            background-color: $line01Color;
            box-sizing: border-box;
            border-radius: 10px;
            color: $whiteColor;
            box-sizing: border-box;
            padding-top: 50px;
            cursor: pointer;
            &:nth-child(1) {
              margin-right: 10px;
              margin-bottom: 10px;
            }
            &:nth-child(2) {
              margin-bottom: 10px;
            }
            &:nth-child(3) {
              margin-right: 10px;
            }
            &.normal {
              &.active {
                background-color: #00b96b;
              }
            }
            &.mild {
              &.active {
                background-color: #4791ff;
              }
            }
            &.caution {
              &.active {
                background-color: #eabe6e;
              }
            }
            &.servere {
              &.active {
                background-color: #f4514d;
              }
            }
          }
        }
      }
      &.anomaly_count {
        ul {
          width: 100%;
          height: 255px;
          li {
            width: 100%;
            height: 63px;
            border-bottom: 1px solid $line01Color;
            box-sizing: border-box;
            padding: 20px 15px;
            vertical-align: middle;
            position: relative;
            .icon {
              width: 7px;
              height: 7px;
              display: inline-block;
              border-radius: 7px;
              vertical-align: middle;
            }
            & > span {
              display: inline-block;
              border-radius: 7px;
              vertical-align: middle;
              margin-left: 10px;
            }
            .txt_box {
              display: inline-block;
              position: absolute;
              top: 20px;
              right: 30px;
              strong {
                font-size: 20px;
                font-family: $montserrat;
                font-weight: 500;
              }
              span {
                display: inline-block;
                margin-left: 4px;
              }
            }
            &:nth-child(1) {
              .icon {
                background-color: #00b96b;
                box-shadow: 0px 0px 10px #00b96b;
              }
            }
            &:nth-child(2) {
              .icon {
                background-color: #4791ff;
                box-shadow: 0px 0px 10px #4791ff;
              }
            }
            &:nth-child(3) {
              .icon {
                background-color: #eabe6e;
                box-shadow: 0px 0px 10px #eabe6e;
              }
            }
            &:nth-child(4) {
              .icon {
                background-color: #f4514d;
                box-shadow: 0px 0px 10px #f4514d;
              }
            }
          }
        }
      }
      &.anomaly_history {
        position: relative;
        .detail_btn {
          position: absolute;
          top: 7px;
          right: 15px;
          padding: 14px 20px 10px 10px;
          background-image: url($baseURL + "common/external_link.svg");
          background-repeat: no-repeat;
          background-position-x: right;
          background-position-y: center;
        }
        .mando_table_wrap {
          height: 255px;
          .item.active {
            background-color: #edfbff;
          }
        }
      }
      &.dtc_map {
        overflow: hidden;
        .map {
          width: 100%;
          height: 255px;
          background-color: #ff0;
        }
      }
    }
  }
}

#modalWrap {
  .float_box {
    height: auto;
  }
  .filter_modal {
    width: 1200px;
    height: 690px;
    padding: 25px;
    box-sizing: border-box;
    #recentFilter {
      display: none;
      width: 100%;
      min-height: 70px;
      border: 1px solid $line01Color;
      border-radius: 10px;
      box-sizing: border-box;

      .filter_list_box {
        width: 100%;
        height: 100%;
        position: relative;

        .float_box {
          width: 100%;
          box-sizing: border-box;
          position: relative;
          margin-top: 0;
          padding: 5px 10px;
          li {
            display: inline-block;
            background-color: #edfbff;
            border: 1px solid $mainColor;
            border-radius: 10px;
            padding: 10px 30px 10px 15px;
            position: relative;
            margin-right: 10px;
            margin-top: 10px;
            &:last-child {
              margin-right: 0;
            }
          }
          .btn_close_item {
            width: 20px;
            height: 20px;
            position: absolute;
            top: 9px;
            right: 4px;
            &::before {
              display: block;
              content: "";
              width: 1.5px;
              height: 10px;
              background-color: $placeHolderColor;
              position: absolute;
              top: 5px;
              left: 8px;
              transform: rotate(45deg);
            }
            &::after {
              display: block;
              content: "";
              width: 1.5px;
              height: 10px;
              background-color: $placeHolderColor;
              position: absolute;
              top: 5px;
              left: 8px;
              transform: rotate(-45deg);
            }
          }
        }
      }
    }
    .list_wrap {
      width: 100%;
      height: 570px;

      //스크롤 기능 추가
      // overflow: auto;
      .table_list {
        box-shadow: none;
        border: 1px solid $line01Color;
        .filter_box {
          padding: 10px 20px;
        }
        .date_filter {
          padding-left: 20px;
          top: 10px;
          right: 20px;
          &:after {
            display: none;
          }
        }
        .total_num {
          box-sizing: border-box;
          padding: 20px;
          padding-bottom: 0;
          font-size: $fontSub;
          span {
            color: $mainColor;
            font-weight: $bold;
            font-size: $fontSub;
          }
        }
      }
      .mando_table_wrap {
        .mando_table {
          position: relative;
        }
      }
    }
  }
  .mando_table_wrap {
    height: 450px;
  }
  .btn_filter {
    display: none;
  }
}
.driving_record_list {
  width: 300px;
  height: 500px;
  box-sizing: border-box;
  overflow-y: auto;
  li {
    position: relative;
    padding: 20px 10px 20px 25px;
    border-bottom: 1px solid #d5dae3;
    cursor: pointer;
    &:not(:first-child) {
    }
    &:hover {
      background-color: #ccf0fb;
    }
    &.active {
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 10px;
        height: 100%;
        background-color: #00b4ed;
      }
    }
    div {
      font-weight: 500;
      &:first-child {
        margin-bottom: 5px;
      }
    }
    .car_driver_wrap {
      display: flex;
      align-items: center;
      .car,
      .driver {
        width: 50%;
        display: flex;
        align-items: center;
        gap: 10px;
        .icon {
          font-size: 22px;
          color: #12a1d5;
        }
      }
    }
  }
}
.carDataEmpty {
  margin-top: 100px;
  text-align: center;
  font-weight: 800;
}
</style>
