<template>
  <div class="chart">
    <canvas ref="chartApiCall" />
  </div>
</template>

<script>
import { Chart, registerables } from "chart.js/auto";

Chart.register(...registerables);
export default {
  name: "ChartController",
  computed: {},
  props: {
    labelsArray: {
      type: Array,
      default: null,
    },
    datasArray: {
      type: Array,
      default: null,
    },
    chartTitle: {
      type: String,
      default: "",
    },
    chartType: {
      type: String,
      default: "line",
    },
  },
  data() {
    return {
      chartApiCall: null,
    };
  },
  watch: {
    datasArray() {
      this.chartApiCall.destroy();
      this.createChart();
    },
  },
  created() {},
  mounted() {
    this.createChart();
  },
  methods: {
    createChart() {
      this.chartApiCall = new Chart(this.$refs.chartApiCall, {
        type: this.chartType,
        data: {
          labels: this.labelsArray,
          datasets: this.datasArray,
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              position: "top",
              //display: false,
            },
            title: {
              display: true,
              text: this.chartTitle,
              color: "rgb(2, 10, 10)",
              font: {
                size: 18,
              },
            },
          },
          responsive: true,
          //maintainAspectRatio: false,
        },
      });
    },
    addData(chart, label, newData) {
      chart.data.labels.push(label);
      chart.data.datasets.forEach((dataset) => {
        dataset.data.push(newData);
      });
      chart.update();
    },
    removeData(chart) {
      chart.data.labels.pop();
      chart.data.datasets.forEach((dataset) => {
        dataset.data.pop();
      });
      chart.update();
    },
  },
};
</script>
<style scoped lang="scss">
.chart {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}
</style>
