<template>
  <div class="setting_pop_cell_contents_wrap">
    <table>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ item.label }}</td>
          <td>{{ item.value }}</td>
        </tr>
      </tbody>
    </table>
    <div class="checkbox-container">
      <div class="checkbox-row">
        <div class="checkbox-item">
          <div class="input_box">
               <div class="checks">
                  <input type="checkbox" id="ch1" /><label for="ch1"></label
                  ><span>Box Layer</span>
                </div>
          </div>
        </div>
        <div class="checkbox-item">
          <div class="input_box">
               <div class="checks">
                  <input type="checkbox" id="ch2" /><label for="ch2"></label
                  ><span>use Butoon</span>
                </div>
          </div>
        </div>
      </div>
    </div>
    <div id="ControllerData">
      Controller 추가 
      <button type="button" class="plus" :class="{ 'clicked': isClicked }"  @click="handleClick" >+</button> 
      <ComponentTypeList @component-type="handleSelectedValue"/>
      <div>
      <div class='divide'
           v-for="(item,index) in components" 
           :key="item.key">
         <component
          v-if="item && item.key && item.type"
          :is="
            item.type === 'calendar'
              ? 'CalendarBar'
              : null
          "
          @updateValue ="updateItem(index,$event)" 
          :interface="item"
          :otherProp="item"
        ></component>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import ComponentTypeList from "./componentTypeList.vue"
import CalendarBar from '../componentType/calendar_bar.vue'
import _ from 'lodash'

export default {
  name: "FilterComponentSetting",
  components:{
    ComponentTypeList,
    CalendarBar
  },
  
  props: {
    otherProp: {
      type: Object,
      required: true,  // 전달받는 값이 반드시 있어야 한다면 required를 true로 설정
    },
    nowIndex:{
      type: Number,
      required:true
    }
  },

  data() {
    return {
      items: [
        { label: 'ID', value: '' },
        { label: 'Title', value: '' },
        ],
      isClicked: false,
      componentType :'',
      components:[],
      layoutComponent :{}
};
  },

  mounted() {
    // 전달받은 otherProp 확인
    this.layoutComponent = _.cloneDeep(this.otherProp);
    this.components = _.cloneDeep(this.layoutComponent.components);
    
    console.log(this)
    this.items[0].value = this.layoutComponent.key;
    this.items[1].value = this.layoutComponent.type;
    // console.log(this.layoutComponent)
    // this.layoutComponent.components  = {}
    // this.layoutComponent.index = this.nowIndex;
  },

  methods: {
      handleClick() {
      
      this.isClicked = true
      setTimeout(() => 
      {
      // 200ms 후에 isClicked를 false로 설정하여 스타일을 원래대로 되돌림
        this.isClicked = false;
      }, 100);
      //
      if(this.componentType===''){
        alert('componentType이 설정되지 않았습니다.')
      }
      else{
        //type , value, scope_start scope_end label_text
        const type = this.componentType;
        const component = {
          type:type,
          labelText:'',
          value:'',
          valueStart:'',
          valueEnd:'',
          key : (this.components.length+1)+'-'+Math.floor(Date.now())
        };
        this.components.push(component);
      }
      },
    handleSelectedValue(value) {
      // 선택된 option의 value 값을 부모로 emit
      this.componentType = value;
    },
    updateItem(index, newValue){
      this.components.splice(index, 1, newValue); // 배열의 값 변경
    },
    save(){
      const components = this.components;
      //값이 제대로 입력되어있는지 확인한다.
      for(let i=0;i<components.length;i++){
        if(components[i].type==='calendar'){
          //날짜가 지정되어있는가?? 
          if(components[i].valueStart===''||components[i].valueEnd===''
          ||components[i].valueStart===null||components[i].valueEnd===null){
            alert(`${i+1}번쨰 calendar 날짜값이 입력되지 않았습니다.`)
            return false;
          }
        }
        if(components.labelText===''){
           alert(`${i+1}번쨰 label Text 값이 입력되지 않았습니다.`)
           return false;
        }
      }
      //components를 상위 부모 객체로 보낸다. 
      console.log(components); 
      console.log(this.items)

    }

  },

  watch:{
    components(){
      this.layoutComponent.components = this.components;
      this.$emit('updateLayoutComponent',this.layoutComponent)
    }
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.port_wrap {
  width: 100%;
  height: 100%;
  padding: 20px;
  background: #f4f4f4;
  position: absolute;
  top: 0px;
  left: 0px;
  -webkit-user-select: none !important;
  -moz-user-select: -moz-none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.port_wrap .component_pannel {
  width: 240px;
  height: calc(100% - 40px - 60px);
  position: absolute;
  top: 80px;
  left: 20px;
  background: white;
  padding: 20px;
  border: 1px solid #d4d4d4;
}
.port_wrap .component_pannel button {
  width: 100%;
  height: 40px;
  background: 00B4ED;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 5px;
}
.port_wrap .component_pannel button:hover {
  background: #00B4ED;
}
.port_wrap .rightCell {
  width: calc(100% - 40px - 240px - 10px);
  right: 20px;
  height: calc(100% - 40px - 60px);
  top: 80px;
  background: white;
  border: 1px solid #d4d4d4;
  position: Absolute;
}
.port_wrap .rightCell .control_pannel {
  width: 100%;
  height: 70px;
  border-top: 1px solid #d4d4d4;
  background: white;
  position: absolute;
  bottom: 0px;
  left: 0px;
  line-height: 70px;
  text-align: right;
  padding-right: 20px;
}
.port_wrap .rightCell .grid_box_wrap {
  width: 100%;
  height: calc(100% - 70px);
  top: 0px;
  left: 0px;
  position: absolute;
  display: grid;
  overflow: auto;
  gap: 4px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 90px 90px 90px 90px 90px 90px 90px 90px 90px 90px;
  padding: 20px;
}
.port_wrap .rightCell .grid_box_wrap.batch_grid .grid_cell {
  height: 90px;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px;
}
.port_wrap .rightCell .grid_box_wrap.batch_grid .grid_cell:hover {
  background: rgba(0, 0, 0, 0.1) !important;
}
.batch_grid {
  z-index: 4;
  background: rgba(0, 0, 0, 0.4);
  height: auto !important;
  cursor: crosshair !important;
}
.batch_grid:after {
  position: absolute;
  z-index: -1;
  color: White;
  display: block;

  font-size: 16px;
  font-weight: normal;
  content: "마우스 드래그로 배치할 영역을 설정해 주세요";
  text-align: center;
  padding-top: 400px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  box-sizing: border-box;
}
.real .grid_cell {
  border: none !important;
  background: none !important;
}
.real .grid_cell:hover {
  border: none !important;
  background: none !important;
}
.batch_close {
  position: Absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: 20px;
  right: 20px;
  background: white;
  z-index: 9;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  color: black;
  font-size: 16px;
}
.batch_grid .grid_cell.marked {
  background: rgba(0, 0, 0, 0.1) !important;
}
.real_grid_cell {
  background: white;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  position: relative;
  padding: 10px;
  padding-top: 0px;
}
.real_grid_cell:hover {
  z-index: 9999;
}
.real_grid_cell h4 {
  display: block;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #d4d4d4;
  text-align: left;
  line-height: 40px;
  font-size: 15px;
  font-weight: normal;
  color: black;
  padding: 0px;
}
.real_grid_cell h4 button {
  width: auto;
  background: 00B4ED;
  color: white;
  font-size: 14px;
  font-weight: normal;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 4px;
  height: 30px;
  float: right;
  cursor: pointer;
  border: none;
  margin-top: 4px;
  margin-left: 4px;
}
.real_grid_cell h4 button:hover {
  background: #00B4ED;
}
.mustblock {
  display: block !important;
}
.control_pannel button {
  border: none;
  background: 00B4ED;
  border-radius: 4px;
  text-align: center;
  height: 40px;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  color: White;
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
  margin-left: 4px;
}
.control_pannel button:hover {
  background: #00B4ED;
}

.control_pannel button {
  border: none;
  background: 00B4ED;
  border-radius: 4px;
  text-align: center;
  height: 40px;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  color: White;
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
  margin-left: 4px;
}
.control_pannel button:hover {
  background: #00B4ED;
}
.top_control {
  width: calc(100% - 40px);
  height: 50px;
  top: 20px;
  left: 20px;
  background: white;
  border: 1px solid #d4d4d4;
  position: Absolute;
  padding: 10px;
  text-align: left;
}
.top_control select {
  width: auto;
  height: 30px;
  border: 1px solid #d4d4d4;
  padding: 0px;
  padding-left: 14px;
  font-size: 14px;
  font-weight: normal;
  color: black;
  padding-right: 40px;
  background-position: right 10px center;
  border-radius: 0px;
  margin-right: 10px;
}
.top_control button {
  width: auto;
  padding-left: 14px;
  padding-right: 14px;
  height: 30px;
  border-radius: 4px;
  background: 00B4ED;
  color: white;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  right: 10px;
  top: 10px;
}
.top_control button:hover {
  background: #00B4ED;
}
.grid_box_wrap.real.layout_mode .moving_pannel {
  display: block;
}
.grid_box_wrap.real.layout_mode .right_wid_pannel {
  display: block;
}
.grid_box_wrap.real.layout_mode .bottom_hei_pannel {
  display: block;
}
.moving_pannel {
  width: 90px;
  height: 90px;
  background: white;
  border: 1px solid #d4d4d4;
  border-radius: 50%;
  position: absolute;
  left: 5px;
  top: 5px;
  display: none;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
}
.right_wid_pannel {
  position: Absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 1px;
  overflow: visible;
  display: none;
}
.right_wid_pannel .left_ar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/right_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  opacity: 0.5;
  position: absolute;
  top: 50%;
  right: 4px;
  cursor: pointer;
}
.right_wid_pannel .left_ar:hover {
  opacity: 1;
}
.right_wid_pannel .right_ar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/left_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  opacity: 0.5;
  position: absolute;
  top: calc(50% + 40px);
  right: 4px;
  cursor: pointer;
}
.right_wid_pannel .right_ar:hover {
  opacity: 1;
}
.bottom_hei_pannel {
  position: absolute;
  width: 100%;
  height: 1px;
  overflow: visible;
  bottom: 0px;
  left: 0px;
  display: none;
}
.bottom_hei_pannel .up_ar {
  position: absolute;
  width: 30px;
  height: 30px;
  opacity: 0.5;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/up_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  bottom: 4px;
  left: 4px;
  cursor: pointer;
}
.bottom_hei_pannel .up_ar:hover {
  opacity: 1;
}
.bottom_hei_pannel .down_ar {
  position: absolute;
  width: 30px;
  height: 30px;
  opacity: 0.5;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/down_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  bottom: 4px;
  left: 44px;
  cursor: pointer;
}
.bottom_hei_pannel .down_ar:hover {
  opacity: 1;
}
.moving_pannel .left_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0px;
  top: 50%;
  margin-top: -20px;
  cursor: pointer;
  background: url("@/assets/right_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .left_ar:hover {
  opacity: 1;
}
.moving_pannel .right_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -20px;
  cursor: pointer;
  background: url("@/assets/left_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .right_ar:hover {
  opacity: 1;
}
.moving_pannel .top_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 50%;
  margin-right: -20px;
  top: 0px;
  cursor: pointer;
  background: url("@/assets/up_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .top_ar:hover {
  opacity: 1;
}
.moving_pannel .down_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 50%;
  margin-right: -20px;
  bottom: 0px;
  cursor: pointer;
  background: url("@/assets/down_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .down_ar:hover {
  opacity: 1;
}
.grid_box_wrap.real.layout_mode .grid_cell {
  border: 1px solid #e6e6e6 !important;
}
.setting_pop {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999999;
  display: none;
}
.setting_pop_cell {
  width: 700px;
  height: 100%;
  position: Absolute;
  top: 0px;
  right: 0px;
  background: White;
  padding: 40px;
}
.setting_pop_cell .setting_pop_cell_x {
  width: 40px;
  height: 40px;
  background: #f4f4f4;
  color: black;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
}
.setting_pop_cell .setting_pop_cell_x:hover {
  background: #00B4ED;
  color: white;
}
.setting_pop_cell h4 {
  display: block;
  font-size: 20px;
  font-weight: normal;
  color: black;
  width: 100%;
  height: 30px;
  line-height: 25px;
  border-bottom: 1px solid #d4d4d4;
  padding: 0px;
  margin-bottom: 20px;
}
.setting_pop_cell table {
  border-collapse: collapse;
  width: 100%;
  height: auto;
}
.setting_pop_cell table th {
  background: #f4f4f4;
  border: 1px solid #d4d4d4;
  padding: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: black;
  vertical-align: middle;
}
.setting_pop_cell table td {
  padding: 4px;
  background: white;
  border: 1px solid #d4d4d4;
  font-size: 14px;
  font-weight: normal;
  color: black;
  text-align: left;
  vertical-align: middle;
}
.setting_pop_cell table td select {
  width: 100%;
  height: 30px;
  border: 1px solid #d4d4d4;
  padding-right: 40px;
  background-position: right 10px center;
  font-size: 14px;
  padding-left: 10px;
  font-weight: normal;
  color: black;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 0px;
}
.setting_pop_cell table td textarea {
  resize: none;
  outline: none;
  width: 100%;
  padding: 20px;
  font-size: 14px;
  font-weight: normal;
  color: black;
  border: 1px solid #d4d4d4;
  height: 200px;
}
.setting_pop_cell table td input[type="text"] {
  width: 100%;
  height: 30px;
  border: 1px solid #d4d4d4;
  padding-left: 10px;
  font-size: 14px;
  font-weight: normal;
  color: black;
}
.checks {
  width: 100%;
  display: block;
  margin-bottom: 10px;
}
.checks span {
  display: inline-block;
  vertical-align: top;
  margin-left: 4px;
}
.setting_pop_cell_bottom_button_box {
  width: 100%;
  height: 60px;
  border-top: 1px solid #d4d4d4;
  text-align: right;
  position: absolute;
  line-height: 60px;
  text-align: center;
  bottom: 0px;
  right: 0px;
  padding-right: 20px;
  padding-left: 20px;
}
.setting_pop_cell_bottom_button_box button {
  height: 40px;
  font-size: 14px;
  font-weight: normal;
  color: white;
  width: auto;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 4px;
  background: 00B4ED;
  border: none;
  cursor: pointer;
  margin-left: 4px;
}
.setting_pop_cell_bottom_button_box button:hover {
  background: #00B4ED;
}
.setting_pop_cell_contents_wrap {
  width: 100%;
  height: calc(100% - 70px);
  position: relative;
  overflow: auto;
}
.grid_box_wrap.real .grid_box_wrap.batch_grid .moving_pannel {
  display: block;
}
.grid_box_wrap.real .grid_box_wrap.batch_grid .right_wid_pannel {
  display: block;
}
.grid_box_wrap.real .grid_box_wrap.batch_grid .bottom_hei_pannel {
  display: block;
}
.vue-grid-layout {
  background: white;
}
.remove {
  position: absolute;
  right: 2px;
  top: 0;
  cursor: pointer;
}
.setting_button {
  display: block;
  width: auto;
  height: 32px;
  line-height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  background: 00B4ED;
  color: white;
  font-size: 14px;
  font-weight: normal;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  top: 2px;
  right: 40px;
}
.vue-grid-item {
  background: white !important;
  border: 1px solid #d4d4d4 !important;
}
.vue-grid-item span.text {
  display: block;
  height: 40px;
  border-bottom: 1px solid #d4d4d4;
  position: absolute;
  left: 0px;
  top: 0px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  font-weight: normal;
  color: black;
  margin: 0px;
}
.vue-grid-item span.remove {
  width: 32px;
  height: 32px;
  top: 2px;
  right: 4px;
  position: absolute;
  background: 00B4ED;
  color: white;
  line-height: 32px;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  font-weight: normal;
}
.vue-grid-item span.remove:hover {
  background: #00B4ED;
}
.vue-grid-item span.setting_button:hover {
  background: #00B4ED;
}
.port_wrap .rightCell {
  overflow: auto;
}
.vue-grid-item {
  padding: 10px;
  box-sizing: border-box;
  padding-top: 50px;
}
.checkbox-container {
  display: flex;
  flex-direction: column;
  gap: 10px; /* 여러 행이 있을 경우 간격을 위한 속성 */
}

.checkbox-row {
  display: flex;
  justify-content: flex-start;
  gap: 20px; /* 각 체크박스 간격 */
}

.checkbox-item {
  display: inline-flex; /* 자식 요소에 맞춰 크기 조정 */
  align-items: center;  /* 체크박스와 레이블을 수직 중앙 정렬 */
  gap: 8px;             /* 체크박스와 레이블 사이 간격 */
}


.checkbox-item div {
  display: inline-block; /* div가 자식 요소의 크기를 가지도록 설정 */
  width: auto;           /* div가 자식 요소 크기에 맞춰짐 */
  height: auto;          /* div가 자식 요소 크기에 맞춰짐 */
}
</style>



<style>
.plus {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.plus.clicked {
  background-color: #007bff; /* 클릭 시 버튼 색상 변경 */
  color: white; /* 클릭 시 글자 색상 변경 */
}
</style>
