<template>
    <select v-model="componentType" @change="sendDataToParent" >
        <option :value="''">Type Select</option>
        <option :value="'calendar'">Calendar bar</option>
        <option :value="'scope'">Scope bar</option>
        <option :value="'select'">Select bar</option>
        <option :value="'search'">Search bar</option>
        <option :value="'check_box'">Check box</option>
        <option :value="'radio_button'">Radio button</option>
    </select>
</template>

<script>
export default {
    data() {
        return {
            componentType :''
        }
    },
    methods:{
        sendDataToParent(){
            this.$emit('component-type',this.componentType)
        }
    }
}
</script>
