<template>
  <VDatePicker
    locale="en-US"
    expanded
    v-model="date"
    trim-weeks
    borderless
    :min-date="minDate"
    :max-date="maxDate"
    is-required
  />
</template>

<script>
window.selectDate = {
  startDate: null,
  endDate: null,
};
export default {
  name: "VCalendarUi_NEW",
  data() {
    const date = new Date();

    const maxDate = new Date();

    const today1 = new Date();
    today1.setDate(today1.getDate() - 1);
    const formattedDate_1 = this.getFormDate(today1);

    const today7 = new Date();
    today7.setDate(today7.getDate() - 7);
    const formattedDate_7 = this.getFormDate(today7);

    const today14 = new Date();
    today14.setDate(today14.getDate() - 14);
    const formattedDate_14 = this.getFormDate(today14);

    const today30 = new Date();
    today30.setDate(today30.getDate() - 30);
    const formattedDate_30 = this.getFormDate(today30);

    const today60 = new Date();
    today60.setDate(today60.getDate() - 60);
    const formattedDate_60 = this.getFormDate(today60);
    return {
      date,
      maxDate,
      formattedDate_1,
      formattedDate_7,
      formattedDate_14,
      formattedDate_30,
      formattedDate_60,
    };
  },
  props: {
    dateInterface: String,
    setDate: String,
    minDate: String,
  },
  watch: {
    //(newValue, oldValue
    date(newValue) {
      if (newValue !== null) {
        const year = newValue.getFullYear();
        const month = String(newValue.getMonth() + 1).padStart(2, "0");
        const day = String(newValue.getDate()).padStart(2, "0");

        const formattedDate = year + "-" + month + "-" + day;

        if (this.dateInterface === "start") {
          window.selectDate.startDate = formattedDate;
          // console.log(this.dateInterface);
          // console.log('Start Date:', window.selectDate.startDate);
        } else if (this.dateInterface === "end") {
          window.selectDate.endDate = formattedDate;
          // console.log(this.dateInterface);
          // console.log('End Date:', window.selectDate.startDate);
        }
      }
    },
    setDate() {
      this.dateSet();
    },
  },
  computed: {},
  mounted() {
    this.dateSet();
  },
  methods: {
    dateSet() {
      // console.log(this.setDate);
      // brefore 1day
      if (this.setDate == "1") {
        const date = this.formattedDate_1;
        const initialDate = this.getInitialDate(date);
        this.date = initialDate;
      }
      // brefore 7day
      if (this.setDate == "7") {
        const date = this.formattedDate_7;
        const initialDate = this.getInitialDate(date);
        this.date = initialDate;
      }
      // brefore 14day
      if (this.setDate == "14") {
        const date = this.formattedDate_14;
        const initialDate = this.getInitialDate(date);
        this.date = initialDate;
      }
      // brefore 30day
      if (this.setDate == "30") {
        const date = this.formattedDate_30;
        const initialDate = this.getInitialDate(date);
        this.date = initialDate;
      }

      // brefore 60day

      if (this.setDate == "60") {
        const date = this.formattedDate_60;
        const initialDate = this.getInitialDate(date);
        this.date = initialDate;
      }

      // for modify page
      if (
        this.setDate !== undefined &&
        this.setDate !== null &&
        this.setDate !== ""
      ) {
        if (
          this.setDate != "1" &&
          this.setDate != "7" &&
          this.setDate != "14" &&
          this.setDate != "30" &&
          this.setDate != "60"
        ) {
          const date = this.setDate;
          const initialDate = this.getInitialDate(date);
          this.date = initialDate;
        }
      }
    },

    getInitialDate(date) {
      const parts = date.split("-");

      const year = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10);
      const day = parseInt(parts[2], 10);
      const initialDate = new Date(year, month - 1, day);

      return initialDate;
    },

    getFormDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const formattedDate = year + "-" + month + "-" + day;
      return formattedDate;
    },
  },
};
</script>
<style>
.vc-container .vc-weekday-1 {
  color: #ea726f !important;
}
.vc-container .vc-weekday-7 {
  color: #4791ff !important;
}
.vc-blue {
  --vc-accent-600: #00b4ed;
}
</style>
